export const calculateCanvasDimensions = (
  container: HTMLDivElement,
  image: HTMLImageElement
) => {
  const screenWidth = container.clientWidth;
  const screenHeight = container.clientHeight;

  const imageAspectRatio = image.width / image.height;
  let canvasWidth = screenWidth;
  let canvasHeight = screenWidth / imageAspectRatio;

  if (canvasHeight > screenHeight) {
    canvasHeight = screenHeight;
    canvasWidth = screenHeight * imageAspectRatio;
  }

  return { canvasWidth, canvasHeight };
};
