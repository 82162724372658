import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./auth";
import { ECookie, setCookie } from "../../../shared";

export const loginWithPassword = async (email: string, password: string) => {
  const userCredential = await signInWithEmailAndPassword(
    auth,
    email,
    password
  );

  const user = userCredential.user;

  const accessToken = await user.getIdToken();

  if (!accessToken) {
    return null;
  }

  setCookie(ECookie.AccessToken, accessToken, 30);

  return user;
};
