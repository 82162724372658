import React from "react";

interface LabeledLineDividerProps {
  label: string;
}

export const LabeledLineDivider: React.FC<LabeledLineDividerProps> = ({
  label,
}) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-white" />
      </div>
      <div className="relative flex justify-center">
        <span className="bg-black px-2 font-circular-book text-base text-white">
          {label}
        </span>
      </div>
    </div>
  );
};
