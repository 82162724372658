export const RecentlyReleasedSectionLoadingSkeleton = () => {
  return (
    <div className=" h-96  w-full">
      <div className="h-6 w-40 animate-pulse rounded bg-gray-200"></div>
      <div className="mt-1 h-10 w-full animate-pulse rounded bg-gray-200"></div>
      <div className="mt-4 flex h-40 w-full justify-around  rounded ">
        <div className="h-64 w-28 animate-pulse bg-gray-200"></div>
        <div className="h-64 w-28 animate-pulse bg-gray-200"></div>
      </div>
    </div>
  );
};
