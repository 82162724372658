import { captureMessage } from "@sentry/react";
import { findProductByGtinRequest } from "../../../api/findProductByGtinRequest";
import { db } from "../firebaseInit";

import { doc, getDoc } from "firebase/firestore";
import { TProduct } from "../../../types";

export async function getRecentlyAddedDupesFilters(): Promise<TProduct[]> {
  try {
    const productGtins = await getGtinsFromFirebase();

    const products = await Promise.all(
      productGtins.map((gtin: string) => findProductByGtinRequest(gtin))
    );

    const filteredProducts: TProduct[] = products.filter(
      (product) => product !== null
    ) as TProduct[];

    return filteredProducts;
  } catch (error) {
    console.log(error);
    captureMessage(
      `Error in getRecentlyAddedDupesFilters: ${JSON.stringify(error)}`
    );
    return [];
  }
}

async function getGtinsFromFirebase(): Promise<string[]> {
  const docRef = doc(db, "homePageFilters", "recentlyReleasedDupes");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return (docSnap.data() as { productGtins: string[] })?.productGtins;
  } else {
    return [];
  }
}
