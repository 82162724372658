import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "../components";
import { GlobalErrorDialog } from "../components/dialogs/GlobalErrorDialog";
import { LoadingPage } from "./LoadingPage";

import { RouteGuard } from "../components/RouteGuard";
import ScrollToTop from "../v2/components/helper/ScrollToTop";

export const Root = () => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <RouteGuard>
        <ScrollToTop />
        <div className=" relative flex  h-screen w-screen flex-col items-center justify-start bg-dark-purple">
          <div className="mt-20 w-128 max-w-full ">
            <h1 className=" mb-2 text-center font-ivypresto-regular  text-5xl leading-11   text-white">
              Wir sind bald zurück - bleibt gespannt!
            </h1>
            <p className=" text-center font-ivypresto-regular text-base  leading-9 text-white">
              Eure Matchies
            </p>
          </div>
          <div
            className="absolute bottom-0 flex h-128 w-full max-w-xl flex-col justify-end bg-cover bg-top  bg-no-repeat px-5 pb-11 pt-20"
            style={{
              backgroundImage: "url(/v2/images/bg-setting.png)",
            }}
          ></div>
        </div>
        <Footer />
        <GlobalErrorDialog />
      </RouteGuard>
    </Suspense>
  );
};
