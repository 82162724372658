import { TProduct } from "../types";
import { getRequest } from "./getRequest";

export const findProductByGtinRequest = async (
  gtin: string
): Promise<TProduct | null> => {
  try {
    const searchUrl = `${
      import.meta.env.VITE_BACKEND_URL
    }/findProductByGtin/${gtin}`;

    const product = await getRequest(searchUrl);

    if (!product.gtin) {
      return null;
    }

    return product;
  } catch (error) {
    console.log(error); // TODO show this to the user
    return null;
  }
};
