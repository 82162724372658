import { selector } from "recoil";
import { userAtom } from "../atoms/userAtom";
import { t } from "i18next";

export const accountInfoSelector = selector({
  key: "accountInfoSelector",
  get: async ({ get }) => {
    const userState = get(userAtom);

    if (!userState?.user) return [];

    const email = userState.user.email;

    const createdAtDate = new Date(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Date(userState.user.createdAt) // TODO: fix lint issue
    ).toLocaleDateString();
    const cratedAtInfo = `${t(
      "ACCOUNT_PAGE.ACCOUNT_CREATED_AT"
    )} ${createdAtDate}`;

    const premiumUserLabel = userState.hasSubscription
      ? "Premium User"
      : "Free User";

    // const subscriptions = get(currentSubscriptionAtom);

    // console.log(subscriptions);

    // const nextBillingLabel = !subscriptions[0]
    //   ? ""
    //   : `${
    //       subscriptions[0]?.cancel_at_period_end
    //         ? pageConfig?.endsAtLabel
    //         : pageConfig?.nextExtensionLabel
    //     } ${new Date(
    //       subscriptions[0]?.current_period_end
    //     ).toLocaleDateString()}`;

    return [email, cratedAtInfo, premiumUserLabel];
  },
});
