import { EIntentType } from "../../types";
import { sendGAEvent } from "./sendGaEvent";

// The following specific events were provided by Florian Bovill as they do not fit
// within the generic form of the other events, each of them was specifically defined

export const gaTrackClickUserIntent = (intent: EIntentType) => {
  sendGAEvent("Click_User_Intent", {
    Search_Product_Intent: intent,
  });
};

export const gaTrackClickMenu = (menuItem: string) => {
  sendGAEvent("Click_Menu", {
    Menu_Item: menuItem,
  });
};

export const gaTrackClickNavAppFilter = (filter: string) => {
  sendGAEvent("interaction_in_app", {
    element: filter,
  });
};

export const gaTrackUserRegistered = (method: string) => {
  sendGAEvent("login", {
    method,
  });

  sendGAEvent("Registrierung", {
    method,
  });
};

export const gaTrackProductClick = (gtin: string) => {
  sendGAEvent("interaction_in_app", {
    element: gtin,
  });
};

export const gaTrackBarcodeSannerClick = () => {
  sendGAEvent("interaction_in_app", {
    element: "Barcode Scanner",
  });
};

export const gaTrackSearchBarClick = () => {
  sendGAEvent("interaction_in_app", {
    element: "Suche",
  });

  // sendGAConversion("9iyaCLuq5YUYEOfd3oQp");
};

export const gaTrackShopLinkClick = (shopLink: string) => {
  sendGAEvent("shop_click", {
    "ref-link": shopLink,
  });
};
