import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ERoutePaths } from "../../../../types";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

import { pickColor } from "./utils/pickColor";
import { Button } from "../../../components/buttons/Button";
import { t } from "i18next";
import { usePreventScrollOnTouch } from "../../../../hooks/usePreventScrollOnTouch";
import { checkIfCircleIsClicked } from "./utils/checkIfCircleIsClicked";
import { getTouchPosition } from "./utils/getTouchPosition";
import { calculateCanvasDimensions } from "./utils/calculateCanvasDimensions";
import { drawCircle } from "./utils/drawCircle";
import { drawCrossair } from "./utils/drawCrossair";

export interface Position {
  x: number;
  y: number;
}

const ImageUploadColorMatchPage = () => {
  const location = useLocation();
  const imageData = location.state.imageDataUrl;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const currentColor = useRef<string | null>(null);
  const [dragging, setDragging] = useState(false);
  const [position, setPosition] = useState<Position>({ x: 100, y: 100 });
  const [chosenImage, setImage] = useState<HTMLImageElement | null>(null);

  usePreventScrollOnTouch(canvasRef);

  if (!imageData) {
    navigate(ERoutePaths.COLORMATCH);
    return null;
  }

  useEffect(() => {
    window.addEventListener("colorPicked", (data: any) => {
      currentColor.current = data.detail;
    });
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (canvas && context) {
      const image = new Image();
      image.src = imageData;

      image.onload = () => {
        if (!containerRef.current) return console.log("containerRef is null");

        const { canvasHeight, canvasWidth } = calculateCanvasDimensions(
          containerRef.current,
          image
        );

        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        setPosition({ x: canvasWidth / 2, y: canvasHeight / 2 });
        context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
        pickColor(position.x, position.y, context);
        drawCrossair(context, position.x, position.y);
        setImage(image);
      };
    }
  }, [imageData]);

  const handleMouseDown = (event: any) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    if (checkIfCircleIsClicked(event, canvas, position)) {
      setDragging(true);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (event: any) => {
    if (dragging) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const { x, y } = getTouchPosition(event, canvas);

      setPosition({ x, y });
    }
  };

  const onPickColor = () => {
    navigate(`/de/scanner/pick-categroy/${currentColor.current}`);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return console.log("canvas is null");

    const context = canvas.getContext("2d");
    if (!context) return console.log("context is null");

    context.clearRect(0, 0, canvas.width, canvas.height);

    if (chosenImage) {
      context.drawImage(chosenImage, 0, 0, canvas.width, canvas.height);
    }

    pickColor(position.x, position.y, context);
    drawCrossair(context, position.x, position.y);
  }, [position]);

  return (
    <div
      className={
        "fixed flex  h-screen w-full items-center justify-center overflow-hidden overscroll-none bg-black"
      }
    >
      <div
        className="relative min-h-screen w-full max-w-xl bg-black object-contain sm:h-screen sm:w-screen"
        ref={containerRef}
      >
        <div className="absolute left-1/2 top-8 z-10 -translate-x-1/2 font-circular-book text-xl text-white">
          Color Match
        </div>
        <button
          type="button"
          className="absolute top-4  left-4 z-10 rounded-full p-4 focus:outline-none"
          onClick={() => navigate(`${ERoutePaths.COLORMATCH}?mode=color`)}
        >
          <span className="sr-only">Close panel</span>
          <ChevronLeftIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>

        <div className="flex h-screen items-center justify-center bg-black">
          <canvas
            ref={canvasRef}
            className=""
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
            onTouchStart={handleMouseDown}
            onTouchEnd={handleMouseUp}
            onTouchMove={handleMouseMove}
          />
          <ColorPreview />

          <div className="absolute bottom-24 z-10 w-full bg-opacity-0 px-8">
            <Button
              styleType="secondary"
              text={t("SCANNER_PAGE.SELECT_COLOR_BUTTON_LABEL")}
              onClick={onPickColor}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ColorPreview = () => {
  const [pickedColor, setPickedColor] = useState("");

  useEffect(() => {
    window.addEventListener("colorPicked", (data: any) => {
      setPickedColor(`#${data.detail}`);
    });
  }, []);

  return (
    <div
      className="absolute bottom-32 left-1/2 z-20 h-20 w-20 -translate-x-1/2 -translate-y-1/2 transform rounded-full  outline  outline-offset-4 outline-white"
      style={{ backgroundColor: pickedColor }}
    ></div>
  );
};

export default ImageUploadColorMatchPage;
