import { atom } from "recoil";
import { TProduct } from "../../types";
import { trackColorMatchCompleteEvent } from "../../Tracking";

export type TColorMatch = {
  hex: string | null;
  matches: TProduct[];
};

export const colorMatchMemoryAtom = atom<TColorMatch | null>({
  key: "colorMatchMemoryAtom",
  default: { hex: null, matches: [] },
  effects: [
    ({ onSet }) => {
      onSet((newMatch, oldMatch) => {
        // @ts-ignore
        if (!newMatch || newMatch.hex === oldMatch.hex) return;
        console.log("Color Match event", newMatch, oldMatch);
        trackColorMatchCompleteEvent(newMatch);
      });
    },
  ],
});
