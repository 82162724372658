import React from "react";
import { classNames } from "../../../../shared";
import { Button } from "../../../components/buttons/Button";
import { useNavigate } from "react-router-dom";

interface ImageTextSectionProps {
  title: string;
  description: string;
  image: string;
  bgColor: string;
  subTitle?: string;
  callToAction?: string;
  titleIcon?: string;
  emphasizedTitle?: string;
  oneLine?: boolean;
}

const ImageTextSection: React.FC<ImageTextSectionProps> = ({
  title,
  description,
  image,
  bgColor,
  subTitle,
  callToAction,
  titleIcon,
  emphasizedTitle,
  oneLine,
}) => {
  const naviagte = useNavigate();

  const onPickColor = () => {
    naviagte("/");
  };

  return (
    <section className={classNames("flex flex-col py-4", bgColor)}>
      <div
        className={classNames(
          " mb-4 w-full   text-center",
          oneLine ? "flex items-center justify-center" : "px-28"
        )}
      >
        <h2
          className={classNames(
            "mb-2 mr-2  font-ivypresto-regular  text-3xl leading-9"
          )}
        >
          {title}
        </h2>
        {emphasizedTitle && (
          <h2 className="mb-2 font-lakeshore-regular text-5xl  leading-9 tracking-wide">
            {emphasizedTitle}
          </h2>
        )}
        {titleIcon ? (
          <div className="flex justify-center ">
            <img className={`h-8  object-contain`} src={titleIcon} alt="logo" />
          </div>
        ) : null}
      </div>
      <div className="relative h-full w-full px-4">
        <img src={image} alt={title} className="w-full rounded-xl " />
        {callToAction ? (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            {" "}
            <Button
              styleType="secondary"
              text={callToAction}
              onClick={onPickColor}
            />
          </div>
        ) : null}
      </div>
      <div className="mt-4 px-4">
        <h2 className="mb-2 text-center font-circular-book text-xl">
          {subTitle}
        </h2>
        <p className="mb-4 text-center font-circular-book text-base text-gray-300">
          {description}
        </p>
      </div>
    </section>
  );
};

export default ImageTextSection;
