import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";

type BottomModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  testId?: string;
};

export const BottomModal = ({
  children,
  isOpen,
  onClose,
  testId,
}: BottomModalProps) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="scrollbar-hide fixed bottom-0 flex w-full items-center justify-center overflow-y-auto">
            <div className="flex min-h-full max-w-xl items-center justify-center  text-center">
              <Transition.Child
                data-cy={testId}
                as={Fragment}
                enter="ease-out duration-500 transform"
                enterFrom="opacity-0 translate-y-40"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-400"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-40"
              >
                {children}
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
