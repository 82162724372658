import { addTwoDigitsAfterComma } from "./addTwoDigitsAfterComma";
import { replaceDotWithComma } from "./replaceDotWithComma";

export const formatSize = (size: string | undefined | null): string => {
  if (!size) return "--,-";

  const [sizeValue, sizeUnit] = size.split(" ");

  return sizeValue
    ? `${addTwoDigitsAfterComma(replaceDotWithComma(sizeValue))} ${sizeUnit}`
    : "--,-";
};
