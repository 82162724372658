// import { trackSubscribedToNewsletter } from "../Tracking";
import { trackNewsletterComplete } from "../Tracking";
import { postRequest } from "./postRequest";

export const subscribeToNewsLetterRequest = async (
  email: string,
  userId: string
) => {
  try {
    const url = `${import.meta.env.VITE_BACKEND_URL}/addUserToMailChimp`;
    await postRequest(url, { email });
    trackNewsletterComplete(userId);
    // trackSubscribedToNewsletter(); // replace with actual user email
  } catch (error) {
    console.log(error);
  }
};
