import { useRecoilValue, useSetRecoilState } from "recoil";
import { getColorMatchesSelector } from "../../../../state/selectors/getColorMatchesSelector";
import { useParams } from "react-router-dom";
import { TProduct } from "../../../../types";
import { ColorMatchCard } from "./ColorMatchCard";
import { useEffect } from "react";
import { colorMatchMemoryAtom } from "../../../../state/atoms/colorMatchMemoryAtom";

export const ColorMatchSlider = () => {
  const { hex, category } = useParams();
  const setColorMatchMemory = useSetRecoilState(colorMatchMemoryAtom);
  const matches = useRecoilValue(
    getColorMatchesSelector({
      selectedColor: hex || null,
      typeFilter: category || null,
    })
  );

  useEffect(() => {
    // track the completion of a color match
    if (hex && matches.length > 0) {
      setColorMatchMemory({ hex, matches });
    }
  }, [hex, matches]);

  if (!matches) return <></>;

  return (
    <div
      id="match-slider"
      className="scrollbar-hide flex h-128 w-full snap-x snap-mandatory overflow-x-scroll scroll-smooth will-change-scroll"
      data-cy="match-slider"
    >
      {matches?.map((match: TProduct, index: number) => (
        <ColorMatchCard index={index} key={index} match={match as TProduct} />
      ))}
    </div>
  );
};
