import { TrackingDataObject, TrackingEventType } from "./types";

export const track = (
  eventType: TrackingEventType,
  dataObject: TrackingDataObject
): Promise<void> =>
  new Promise((resolve) => {
    if (typeof window === "undefined" || !window.utag) return resolve();

    window.utag.track(eventType, dataObject, () => resolve());
  });
