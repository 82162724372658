export const addTwoDigitsAfterComma = (input: string): string => {
  const newInput = input.includes(",")
    ? input.split(",")[1].length === 1
      ? `${input}0`
      : input
    : `${input},00`;

  if (newInput.includes(",00")) {
    return newInput.split(",")[0];
  }

  return newInput;
};
