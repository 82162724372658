import Cookies from "js-cookie";

export function getCookie(name: ECookie): string | undefined {
  return Cookies.get(name);
}

export function setCookie(name: ECookie, value: string, days: number) {
  Cookies.set(name, value, { expires: days });
}

export function deleteCookie(name: ECookie) {
  Cookies.remove(name);
}

export function getAllCookies() {
  return document.cookie.split(";").map((cs) => {
    const [key, value] = cs.split("=");
    return { [key]: value };
  });
}

export enum ECookie {
  AccessToken = "accessToken",
  OnBoarded = "onBoarded",
  DesiredMatch = "desiredMatch",
}
