import React from "react";
import { useFormContext } from "react-hook-form";

type CheckBoxLabelGroupProps = {
  label: string | React.ReactNode;
  rules: any;
  name: string;
};

const CheckBoxLabelGroup: React.FC<CheckBoxLabelGroupProps> = ({
  label,
  rules,
  name,
}) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <CheckBox rules={rules} name={name} />
      </div>
      <div className="ml-3 text-left leading-6">
        <label
          htmlFor="comments"
          className="font-circular-medium text-xs text-white"
        >
          {label}
        </label>
      </div>
    </div>
  );
};

type CheckBoxProps = {
  rules: any;
  name: string;
};

const CheckBox = ({ rules, name }: CheckBoxProps) => {
  const { register } = useFormContext();

  return (
    <input
      id="comments"
      data-cy={name}
      aria-describedby="comments-description"
      type="checkbox"
      className="h-6 w-6 rounded  border-black bg-wine-red accent-black "
      {...register(name, { ...rules })}
    />
  );
};

export default CheckBoxLabelGroup;
