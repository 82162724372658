import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { Root } from "./pages";
import { NotFoundPage } from "./v2/pages/notFound/NotFoundPage.v2";
import { RecoilRoot } from "recoil";
import { LoadingPage } from "./pages/LoadingPage";
import { AdminProductPage } from "./pages/AdminProductPage";
import { MoreSettingsPage } from "./pages/MoreSettingsPage";
import { AccountPage } from "./v2/pages/account/AccountPageV2";
import { SearchPage as SearchPageV2 } from "./v2/pages/search/SearchPage.v2";
import { AuthPage } from "./v2/pages/auth/AuthPage.v2";
import { MatchPage } from "./v2/pages/match/MatchPage.v2";
import { ProductDetailPage as ProductDetailPageV2 } from "./v2/pages/details/ProductDetailPage";
import { HomePage as HomePageV2 } from "./v2/pages/home/HomePage.v2";
import { MenuPage } from "./v2/pages/menu/MenuPage.v2";
import {
  initSentry,
  sentryCreateBrowserRouter,
} from "./thridparty/sentry/sentry";
import "./locales/i18n";
import { ProtectedRoute } from "./components/RouteGuard";
import { ProductDetailPageSkeletonLoading } from "./v2/pages/details/ProductDetailPageSkeletonLoading";
import { InfoPage } from "./v2/pages/info/InfoPage.v2";
import { ScannerPage } from "./v2/pages/scanner/ScannerPage";
import { InstoreScannerMatchPage } from "./v2/pages/scanner/childrenPages/InstoreScannerMatchPage";
import { ColorScannerMatchPage } from "./v2/pages/scanner/childrenPages/ColorScannerMatchPage";
import { CategoryColorMatchPickerPage } from "./v2/pages/scanner/childrenPages/CategoryColorMatchPickerPage";
import { MatchPageForColor } from "./v2/pages/match/MatchPageForColor";
import { ProductDetailPageColorMatch } from "./v2/pages/details/ProductDetailPageColorMatch";
import { ERoutePaths } from "./types";
import { AboutUsPage } from "./v2/pages/aboutUs/AboutUsPage";
import ImageUploadColorMatchPage from "./v2/pages/scanner/childrenPages/ImageUploadColorMatchPage";
import SDKProvider from "./thridparty/scandit/sdk";
import { AuthCodePage } from "./v2/pages/auth/AuthCodePage";

const devOnlyRoutes =
  import.meta.env.VITE_TEALIUM_ENVIRONMENT === "dev"
    ? [
        {
          path: "/de/9999",
          element: <AdminProductPage />,
        },
        {
          path: "/de/9999/more-settings",
          element: <MoreSettingsPage />,
        },
      ]
    : [];

const protectedRoutes = [
  {
    path: ERoutePaths.SEARCH,
    element: <SearchPageV2 />,
  },
  {
    path: "/de/:originGtin/match-details/:matchGtin",
    element: (
      <Suspense fallback={<ProductDetailPageSkeletonLoading />}>
        <ProductDetailPageV2 />
      </Suspense>
    ),
  },
  {
    path: "/de/dupes/:gtin",
    element: <MatchPage />,
  },
  {
    path: "/de/color-match/:category/:hex",
    element: <MatchPageForColor />,
  },
  {
    path: "/de/details/:gtin",
    element: <ProductDetailPageColorMatch />,
  },
  {
    path: "/de/login",
    element: <AuthPage />,
  },
  {
    path: "/de/account",

    element: <AccountPage />,
  },
  {
    path: "/de/menu",
    element: <MenuPage />,
  },
  {
    path: "/de/scanner",
    element: <ScannerPage />,
    children: [
      {
        path: "/de/scanner/instore-match",
        element: <InstoreScannerMatchPage />,
      },
      {
        path: ERoutePaths.COLORMATCH_UPLOAD,
        element: <ImageUploadColorMatchPage />,
      },
      {
        path: ERoutePaths.COLORMATCH,
        element: <ColorScannerMatchPage />,
      },
      {
        path: `${ERoutePaths.COLORMATCH_PICK_CATEGORY}/:hex`,
        element: <CategoryColorMatchPickerPage />,
      },
    ],
  },
  {
    path: "/",
    element: <HomePageV2 />,
  },
];

const routeChildren = [
  ...[
    {
      path: "/de/faq",
      element: <InfoPage />,
    },
    {
      path: "/de/about-us",
      element: <AboutUsPage />,
    },
    {
      path: "/de/tutorial",
      element: <InfoPage />,
    },
    {
      path: "/de/nutzungsbedingungen",
      element: <InfoPage />,
    },
    {
      path: "/de/datenschutz",
      element: <InfoPage />,
    },
    {
      path: "/de/kontakt",
      element: <InfoPage />,
    },
    {
      path: "/de/impressum",
      element: <InfoPage />,
    },
    {
      path: "/de/loading",
      element: <LoadingPage />,
    },
    {
      path: "/de/login",
      element: <AuthPage />,
    },
    {
      path: "/de/auth-link",
      element: <AuthCodePage />,
    },
    {
      path: "/",
      element: <ProtectedRoute />,
      children: protectedRoutes,
    },
  ],
  ...devOnlyRoutes,
];

initSentry();

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: routeChildren,
  },
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  // <StrictMode>
  <SDKProvider>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </SDKProvider>
  // </StrictMode>
);
