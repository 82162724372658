import React from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { recentlyAddedDupesAtom } from "../../../../state/atoms/recentlyAddedDupesAtom";
import { useTranslation } from "react-i18next";
import { trackProductClick } from "../../../../Tracking/general/generalTrackingEvents";
import { useTrackRecentlyReleasedImpression } from "../../../../hooks/useTrackRecentlyReleasedImpression";

const CDN_URL =
  import.meta.env.VITE_CDN_URL || "https://www.claire.digital/cdn/claire/";

export const RecentlyReleasedSection: React.FC = () => {
  const { t } = useTranslation();
  const recentlyReleasedProducts = useRecoilValue(recentlyAddedDupesAtom);

  useTrackRecentlyReleasedImpression(recentlyReleasedProducts);

  return (
    <div className="w-full ">
      <div>
        <h2 className="font-circular-book text-base uppercase">
          {t("HOME_PAGE.RECENTLY_RELEASED_SECTION_TITLE")}
        </h2>
        <p className="font-ivypresto-regular text-2xl ">
          {t("HOME_PAGE.RECENTLY_RELEASED_SECTION_SUBTITLE")}
        </p>
      </div>
      <div className="scrollbar-hide mt-7 flex w-full overflow-auto">
        {recentlyReleasedProducts.map((product, index) => (
          <Link
            key={index}
            className="mr-8 w-40 shrink-0"
            to={`/de/dupes/${product?.gtin}`}
            onClick={() =>
              product && trackProductClick(product, index, "start_page")
            }
          >
            <img
              alt={product?.imagefront}
              src={`${CDN_URL}${product?.imagefront}`}
              className="h-40 w-40 rounded-lg object-contain "
            />
            <div className="mt-4 mb-4 flex flex-col items-center justify-center">
              <span className="font-circular-medium text-xs uppercase">
                {product?.brand}
              </span>
              <span className="font-circular-book text-xs text-black">
                {product?.range1}
              </span>
              <span className="font-circular-book text-xs  capitalize text-gray-300">
                {product?.name}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
