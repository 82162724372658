import { useEffect, useRef } from "react";
import { trackProductView } from "../Tracking/tealium";
import { TDupeProduct } from "../types";

export const useTrackProductView = (
  product: TDupeProduct | null,
  path: string
) => {
  const pathBefore = useRef<string>();

  useEffect(() => {
    if (
      path.includes("/match-details") &&
      path !== pathBefore.current &&
      product
    ) {
      pathBefore.current = path;
      trackProductView(product);
    }
  }, [path, product]);
};
