import { useNavigate } from "react-router-dom";
import { classNames } from "../../../../shared";
import { Button } from "../../../components/buttons/Button";

interface TitleShowCaseSectionProps {
  title: string;
  description: string;
  image: string;
  bgColor: string;
  subTitle?: string;
  callToAction?: string;
  emphasizedTitle?: string;
}

export const TitleShowCaseSection = ({
  title,
  description,
  image,
  bgColor,
  subTitle,
  callToAction,
  emphasizedTitle,
}: TitleShowCaseSectionProps) => {
  const naviagte = useNavigate();
  const onPickColor = () => {
    naviagte("/");
  };

  return (
    <section className={classNames("flex flex-col pt-8", bgColor)}>
      <div className=" mb-2 w-full px-20 text-center">
        <h2 className="mb-1 font-ivypresto-regular text-3xl leading-9 text-white">
          {title}
        </h2>
        {emphasizedTitle && (
          <h2 className="mb-8 font-lakeshore-regular text-5xl leading-9  tracking-wide text-white">
            {emphasizedTitle}
          </h2>
        )}
      </div>
      {callToAction && (
        <div className="mb-4 px-28">
          <Button
            styleType="secondary"
            text={callToAction}
            onClick={onPickColor}
          />
        </div>
      )}

      <div className="relative h-full w-full px-4">
        <img src={image} alt={title} className="w-full rounded-xl " />
      </div>
    </section>
  );
};
