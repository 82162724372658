export const getIndicatorPosition = (
  videoRef: HTMLDivElement,
  colorPickerIndicatorRef: HTMLDivElement
) => {
  return {
    left: `${
      videoRef.offsetLeft +
      videoRef.offsetWidth / 2 -
      colorPickerIndicatorRef.offsetWidth / 2
    }px`,
    top: `${
      videoRef.offsetTop +
      videoRef.offsetHeight / 3 -
      colorPickerIndicatorRef.offsetHeight / 2
    }px`,
  };
};
