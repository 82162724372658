import { Position } from "../ImageUploadColorMatchPage";

export function checkIfCircleIsClicked(
  event: any,
  canvas: HTMLCanvasElement,
  position: Position
): boolean {
  let x: number;
  let y: number;

  if (event.clientX) {
    const rect = canvas.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
  } else {
    const touch = event.touches[0];
    const rect = canvas.getBoundingClientRect();
    x = touch.clientX - rect.left;
    y = touch.clientY - rect.top;
  }

  const dx = position.x - x;
  const dy = position.y - y;

  // Check if the mouse is inside the circle
  if (Math.sqrt(dx * dx + dy * dy) < 50) {
    return true;
  }
  return false;
}
