import { useMemo } from "react";
import { useSearchResults } from "../../../../hooks";
import { TProduct } from "../../../../types";
import { Link } from "react-router-dom";
import { truncateString } from "../../../utils/truncateString";
import { useSearchResultsPagination } from "../../../components/hooks/useSearchResultsScroll";
import { t } from "i18next";
import { trackProductClick } from "../../../../Tracking/general/generalTrackingEvents";
import { formatPrice } from "../../../utils/formatPrice";
import { formatSize } from "../../../utils/formatSize";
import { useTrackProductImpression } from "../../../../hooks/useTrackProductImpression";
import { isPriceInRange } from "../../../utils/priceInRange";

const CDN_URL =
  import.meta.env.VITE_CDN_URL || "https://www.claire.digital/cdn/claire/";

export const SearchResultList = () => {
  const searchResults = useSearchResults();
  useSearchResultsPagination();

  useTrackProductImpression(searchResults?.products || null);

  return (
    <div>
      {searchResults?.products.length > 0 ? (
        searchResults.products.map((product, index) => (
          <div className="mb-6" key={`${product.gtin}-${index}`}>
            <SearchResultCard product={product} index={index} />
          </div>
        ))
      ) : (
        <div className="relative">
          <div className="absolute top-10 z-10 flex w-full items-center justify-center  text-center">
            <span className=" w-full font-ivypresto-regular text-2xl text-black">
              {t("SHARED.NO_PRODUCTS_FOUND")}
            </span>
          </div>
          <div className="absolute top-36 z-10 flex w-full items-center justify-center px-4  text-center">
            <span className=" font-circular-book text-base text-black">
              {t("SHARED.NO_PRODUCTS_FOUND_DESCRIPTION")}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

type TSearchResultCardProps = {
  product: TProduct;
  index: number;
};

const SearchResultCard = ({ product, index }: TSearchResultCardProps) => {
  const webpUrl = useMemo(
    () => product.imagefront.replace(".png", ".webp"),
    [product.imagefront]
  );

  const brandname = truncateString(product.brand, 15);
  const productName = truncateString(product.name, 24);
  const rangeName = truncateString(product.range1, 24);

  const matchUrl = `/de/dupes/${product.gtin}`;

  const price = isPriceInRange(Number(product.price_de)) ? (
    <>
      {t("SHARED.FROM_LABEL")}
      <span>&sup1;</span> {formatPrice(product.price_de)}
      <span>&#8202;</span>€
      <span className="text-xs text-gray-300"> {t("SHARED.VAT_LABEL")} </span>
    </>
  ) : (
    t("SHARED.PRICE_NOT_AVAILABLE")
  );

  return (
    <div
      className="flex max-h-40  rounded-lg shadow-md "
      data-cy="search-result-item"
    >
      <Link
        to={matchUrl}
        className="w-1/3 transform bg-gray-100 p-5 transition-transform active:scale-95"
        onClick={() => trackProductClick(product, index, "product_search")}
      >
        <img
          className="h-full w-full rounded-l-lg object-contain"
          src={`${CDN_URL}/${webpUrl}`}
          alt=""
        />
      </Link>
      <Link
        to={matchUrl}
        className="flex w-2/3 transform flex-col justify-center px-6 py-4 transition-transform active:scale-95"
      >
        <span className="font-circular-medium text-base uppercase">
          {brandname}
        </span>
        <span className=" font-circular-book text-sm text-black">
          {rangeName}
        </span>
        <span className="mb-2 font-circular-book text-sm text-gray-300">
          {productName}
        </span>
        <span className="font-circular-book text-base">{price}</span>
        <span className="font-circular-book text-sm text-gray-300">
          {formatSize(product.sizeml)}
        </span>
      </Link>
    </div>
  );
};
