import React from "react";
import { classNames } from "../../../shared";

interface DefaultButtonProps {
  onClick?: () => void;
  text?: string;
  type?: "button" | "submit" | "reset";
  mode?: "light" | "dark";
  disabled?: boolean;
  loading?: boolean;
  styleType?: "primary" | "secondary";
  testId?: string;
}

export const Button: React.FC<DefaultButtonProps> = ({
  onClick,
  text,
  type,
  loading,
  mode = "dark",
  styleType = "primary",
  testId,
}) => {
  const buttonStyle = {
    primary: {
      light: "bg-white text-black",
      dark: "bg-black text-white",
    },
    secondary: {
      light: "bg-black text-white border-white",
      dark: "bg-white text-white bg-opacity-30 border-2 border-white ",
    },
  };

  return (
    <button
      data-cy={testId}
      disabled={loading}
      type={type || "button"}
      className={classNames(
        "text-lg h-14 w-full rounded px-2 font-circular-book shadow-sm active:opacity-40 ",
        buttonStyle[styleType][mode]
      )}
      onClick={onClick}
    >
      {" "}
      {loading ? <LoadingSpinner /> : text}
    </button>
  );
};

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="h-6 w-6 animate-spin rounded-full border-b-2 border-white"></div>
    </div>
  );
};
