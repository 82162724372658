import { useRecoilValue, useSetRecoilState } from "recoil";
import { selectedProductToDupeSelector } from "../../../../state/selectors/selectedProductToDupeSelector";
import { matchingDupesSelector } from "../../../../state/selectors/matchingDupesSelector";

import { MatchCard } from "./MatchCard";
import { useEffect } from "react";
import { currentlyVisibleMatchAtom } from "../../../../state/atoms/currentlyVisibleMatchAtom";
import { saveLastSeenDupesWithHighestDiscount } from "../../../../thridparty/firebase/db/saveLastSeenDupesWithHighestDiscount";
import { useTrackInstoreMatch } from "../../../../hooks/useTrackInstoreMatch";

export const MatchSlider = () => {
  const matches = useRecoilValue(matchingDupesSelector(undefined));
  const selectedProductToMatch = useRecoilValue(
    selectedProductToDupeSelector(null)
  );
  const setCurrentlyVisibleMatch = useSetRecoilState(currentlyVisibleMatchAtom);

  useTrackInstoreMatch(matches);

  useEffect(() => {
    if (!matches || !selectedProductToMatch) return;
    saveLastSeenDupesWithHighestDiscount(matches, selectedProductToMatch);
  }, [matches, selectedProductToMatch]);

  useEffect(() => {
    if (matches?.length) setCurrentlyVisibleMatch(matches[0].data.colorhex);
  }, [matches]);

  if (!selectedProductToMatch) return <></>;

  return (
    <div
      id="match-slider"
      className="scrollbar-hide flex h-128 w-full snap-x snap-mandatory overflow-x-scroll scroll-smooth will-change-scroll"
      data-cy="match-slider"
    >
      {matches?.map((match, index) => (
        <MatchCard
          index={index}
          key={index}
          match={match}
          productToMatch={selectedProductToMatch}
          isLast={index === matches.length - 1}
        />
      ))}
    </div>
  );
};
