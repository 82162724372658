import { Dialog } from "@headlessui/react";
import { BottomModal } from "../../components/modals/BottomModal";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { applyActionCode } from "firebase/auth";
import { auth } from "../../../thridparty/firebase/auth";
import { t } from "i18next";
import { captureException } from "@sentry/react";

type Inputs = {
  email: string;
};

enum VerifyState {
  loading,
  error,
  done,
}

export const VerifyEmailModal = ({
  isOpen,
  actionCode,
  onClose,
}: {
  actionCode: string | null;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const methods = useForm<Inputs>();
  const navigate = useNavigate();
  const [verifyState, setVerifyState] = useState<VerifyState>(
    VerifyState.loading
  );

  const onSubmit = async () => {
    navigate("/");
  };

  useEffect(() => {
    if (actionCode) {
      applyActionCode(auth, actionCode)
        .then(() => {
          setVerifyState(VerifyState.done);
        })
        .catch((error) => {
          captureException(error);
          setVerifyState(VerifyState.error);
        });
    }
  }, [actionCode]);

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      testId="forgot-password-bottom-modal"
    >
      <Dialog.Panel
        className="w-full rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all"
        data-cy="verify-email-modal"
      >
        <div className="absolute right-0 top-8  pr-4 pt-4 sm:block">
          <button
            data-cy="forgot-password-bottom-modal-close-button"
            type="button"
            className="rounded-md   text-white hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {verifyState === VerifyState.loading && <LoadingState />}
            {verifyState === VerifyState.error && <ErrorState />}
            {verifyState === VerifyState.done && <DoneState />}
            {(verifyState === VerifyState.done ||
              verifyState === VerifyState.error) && (
              <div className="mb-5">
                <Button
                  type="submit"
                  text={t("THANK_YOU_PAGE.BUTTON_LABEL")}
                  styleType="secondary"
                />
              </div>
            )}
          </form>
        </FormProvider>
      </Dialog.Panel>
    </BottomModal>
  );
};

export const LoadingState = () => {
  return (
    <div data-cy="verify-email-modal-loading">
      <div className="mt-8 mb-5 flex w-full items-center justify-center ">
        <h2 className="font-circular-medium text-2xl font-medium text-white">
          {t("VERIFY_EMAIL_PAGE.LOADING.TITLE")}
        </h2>
      </div>
      <div className="flex w-full items-center justify-center">
        <span className="mb-6 font-circular-book text-base text-white">
          {t("VERIFY_EMAIL_PAGE.LOADING.DESCRIPTION")}
        </span>
      </div>
    </div>
  );
};

export const ErrorState = () => {
  return (
    <div data-cy="verify-email-modal-error">
      <div className="mt-8 mb-5 flex w-full items-center justify-center ">
        <h2 className="font-circular-medium text-2xl font-medium text-white">
          {t("VERIFY_EMAIL_PAGE.ERROR.TITLE")}
        </h2>
      </div>
      <div className="flex w-full items-center justify-center">
        <span className="mb-6 font-circular-book text-base text-white">
          {t("VERIFY_EMAIL_PAGE.ERROR.DESCRIPTION")}
        </span>
      </div>
    </div>
  );
};

export const DoneState = () => {
  return (
    <div data-cy="verify-email-modal-done">
      <div className="mt-8 mb-5 flex w-full items-center justify-center ">
        <h2 className="font-circular-medium text-2xl font-medium text-white">
          {t("VERIFY_EMAIL_PAGE.SUCCESS.TITLE")}
        </h2>
      </div>
      <div className="flex w-full items-center justify-center">
        <span className="mb-6 font-circular-book text-base text-white">
          {t("VERIFY_EMAIL_PAGE.SUCCESS.DESCRIPTION")}
        </span>
      </div>
    </div>
  );
};
