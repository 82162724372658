import { selectorFamily } from "recoil";
import { allProductsAtom } from "../atoms/allProductsAtom";
import { TProduct } from "../../types";

export const selectSingleProductSelector = selectorFamily({
  key: "selectSingleProductSelector",
  get:
    (gtin) =>
    async ({ get }) => {
      if (!gtin) return null;

      const allProducts: TProduct[] = get(allProductsAtom);

      return (
        allProducts.find((product: TProduct) => product.gtin === gtin) || null
      );
    },
});
