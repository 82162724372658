import { useSearchParams } from "react-router-dom";
import { VerifyEmailModal } from "./VerifyEmailModal";
import { ChangePasswordModal } from "./ChangePasswordModal";

// This component is to handle the verify email and forgot password actions provided by firebase
// https://firebase.google.com/docs/auth/custom-email-handler

export const AuthCodePage = () => {
  const [searchParams] = useSearchParams();

  const mode: string | null = searchParams.get("mode");

  const actionCode: string | null = searchParams.get("oobCode");

  return (
    <div className="flex w-full items-center justify-center ">
      <div
        className="flex min-h-screen w-full max-w-xl flex-col justify-between bg-cover bg-top  bg-no-repeat px-5 pb-11 pt-20"
        style={{
          backgroundImage: "url(/v2/images/bg-setting.png)",
        }}
      >
        <img
          className={` h-40 w-full object-contain`}
          src="/matched_logo.png"
          alt="logo"
        />
      </div>
      <div>
        <VerifyEmailModal
          actionCode={actionCode}
          isOpen={mode === "verifyEmail"}
          onClose={() => {}}
        />
        <ChangePasswordModal
          actionCode={actionCode}
          isOpen={mode === "resetPassword"}
          onClose={() => {}}
        />
      </div>
    </div>
  );
};
