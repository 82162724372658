export const extractNumberFromString = (
  str: string | undefined | null
): number => {
  if (!str) return NaN;
  const numberRegex = /[0-9]+(\.[0-9]+)?/;
  const match = str.match(numberRegex);
  if (match) {
    return Number(match[0]);
  }
  return NaN;
};
