import { useState, useEffect } from "react";
import { searchPaginationAtom } from "../../../state/atoms";
import { useSetRecoilState } from "recoil";
export const useSearchResultsPagination = () => {
  const setSearchPagination = useSetRecoilState(searchPaginationAtom);
  const [lastWindowHeight, setLastWindowHeight] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      const currentPosition = window.scrollY;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      if (currentPosition > height - 400 && height !== lastWindowHeight) {
        setLastWindowHeight(height);
        setSearchPagination((prev) => prev + 1);
      }
    };
    window.addEventListener("scroll", updatePosition);

    return () => window.removeEventListener("scroll", updatePosition);
  }, [lastWindowHeight]);
};
