export function drawCrossair(
  context: CanvasRenderingContext2D,
  x: number,
  y: number
) {
  context.beginPath();
  context.moveTo(x - 15, y);
  context.lineTo(x - 6, y);
  context.moveTo(x + 6, y);
  context.lineTo(x + 15, y);
  context.moveTo(x, y - 15);
  context.lineTo(x, y - 6);
  context.moveTo(x, y + 6);
  context.lineTo(x, y + 15);
  context.strokeStyle = "white";
  context.lineWidth = 2;
  context.stroke();
}
