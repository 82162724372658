
      if (import.meta.env.MODE !== "development") {
        window.openCookieBanner = () => {
          document.getElementsByClassName("cmpboxrecalllink")[0].click();
        };
        window.utag_cfg_ovrd = { noview: true };
        window.VITE_SCANDIT_KEY = import.meta.env.VITE_SCANDIT_KEY;
        var tealiumSyncScript = document.createElement("script");
        tealiumSyncScript.setAttribute(
          "src",
          `https://tags.tiqcdn.com/utag/cosnova/shaid/${
            import.meta.env.VITE_TEALIUM_ENVIRONMENT
          }/utag.sync.js`
        );
        document.head.appendChild(tealiumSyncScript);

        var tealiumScript = document.createElement("script");
        tealiumScript.setAttribute(
          "src",
          `https://tags.tiqcdn.com/utag/cosnova/shaid/${
            import.meta.env.VITE_TEALIUM_ENVIRONMENT
          }/utag.js`
        );
        document.head.appendChild(tealiumScript);

        !(function (c, h, i, m, p) {
          (m = c.createElement(h)),
            (p = c.getElementsByTagName(h)[0]),
            (m.async = 1),
            (m.src = i),
            p.parentNode.insertBefore(m, p);
        })(
          document,
          "script",
          "https://chimpstatic.com/mcjs-connected/js/users/ceddd331ad6b64881e0ffd2e4/2fd1e0064783cc3ce3e8ce902.js"
        );
      }
    