export const SearchResultCardSkeleton = () => {
  return (
    <>
      <div className="mb-4 flex max-h-40 animate-pulse rounded-lg shadow-md">
        <div className="w-1/3 transform animate-pulse bg-gray-100 p-5 transition-transform active:scale-95">
          <div className="h-full w-full rounded-l-lg object-contain"></div>
        </div>
        <div className="flex w-2/3 transform animate-pulse flex-col justify-center px-6 py-4 transition-transform active:scale-95">
          <span className="font-circular-medium text-base">...</span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="mb-2 animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="animate-pulse font-circular-book text-base">
            ...
            <span></span>
          </span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
        </div>
      </div>
      <div className="mb-4 flex max-h-40 animate-pulse rounded-lg shadow-md">
        <div className="w-1/3 transform animate-pulse bg-gray-100 p-5 transition-transform active:scale-95">
          <div className="h-full w-full rounded-l-lg object-contain"></div>
        </div>
        <div className="flex w-2/3 transform animate-pulse flex-col justify-center px-6 py-4 transition-transform active:scale-95">
          <span className="font-circular-medium text-base">...</span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="mb-2 animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="animate-pulse font-circular-book text-base">
            ...
            <span></span>
          </span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
        </div>
      </div>
      <div className="mb-4 flex max-h-40 animate-pulse rounded-lg shadow-md">
        <div className="w-1/3 transform animate-pulse bg-gray-100 p-5 transition-transform active:scale-95">
          <div className="h-full w-full rounded-l-lg object-contain"></div>
        </div>
        <div className="flex w-2/3 transform animate-pulse flex-col justify-center px-6 py-4 transition-transform active:scale-95">
          <span className="font-circular-medium text-base">...</span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="mb-2 animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
          <span className="animate-pulse font-circular-book text-base">
            ...
            <span></span>
          </span>
          <span className="animate-pulse font-circular-book text-sm text-gray-300">
            ...
          </span>
        </div>
      </div>
    </>
  );
};
