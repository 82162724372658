import { atom, selector } from "recoil";
import { TProduct } from "../../types";
import { getAllProductsRequest } from "../../api/getAllProductsRequest";

export const allProductsAtom = atom<TProduct[]>({
  key: "allProductsAtom",
  default: selector({
    key: "allProductsAtom/Default",
    get: async () => await getAllProductsRequest(),
  }),
});
