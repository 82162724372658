import { useRecoilValue } from "recoil";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { accountInfoSelector } from "../../../state/selectors/accountInfoSelector";
import { DeleteAccountModal } from "./components/DeleteAccountModal";
import { Link } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export const AccountPage = () => {
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);
  const userInformations = useRecoilValue(accountInfoSelector);
  const { t } = useTranslation();

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col   px-5 pb-11 pt-4">
        <div className="relative flex w-full justify-center py-10">
          <Link to="/de/menu" className="absolute left-0  ">
            <ChevronLeftIcon className="h-6 w-6 text-black" />
          </Link>
          <img
            className={`h-10 w-full object-contain`}
            src="/matched_logo_black.png"
            alt="logo"
          />
        </div>

        <div className="mt-9 flex min-h-[calc(70vh)] flex-col justify-between ">
          <div className="flex flex-col">
            <h1 className="font-circular-bold text-2xl">
              {" "}
              {t("ACCOUNT_PAGE.TITLE")}
            </h1>
            {userInformations.map((userInformation, index) => (
              <span key={index} className="mt-8 font-circular-medium text-base">
                {userInformation}
              </span>
            ))}
            <button
              onClick={() => setDeleteAccountOpen(true)}
              className="mt-8 flex justify-start  font-circular-medium text-base text-black"
            >
              {t("ACCOUNT_PAGE.DELETE_ACCOUNT_BUTTON_LABEL")}
            </button>
          </div>
          <div className="flex flex-col"></div>
        </div>

        <DeleteAccountModal
          isOpen={deleteAccountOpen}
          onClose={() => setDeleteAccountOpen(false)}
        />
      </div>
    </div>
  );
};
