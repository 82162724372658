import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "./auth";

export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider();

  const result = await signInWithPopup(auth, provider);

  const credential = GoogleAuthProvider.credentialFromResult(result);

  if (!credential) {
    return null;
  }

  // The signed-in user info.
  const user = result.user;

  return user;
};
