import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { searchPaginationAtom } from "../state/atoms";
import { shownSearchResultsSelector } from "../state/selectors";
import { TSearchProductsRequestResponse } from "../types";

// This hook is used to solve the problem of pagination in the search results.
// If a user scrolls down to page four but then enters a new search term, the pagination resets to 1
export function useSearchResults() {
  const [previousResults, setPreviousResults] =
    useState<TSearchProductsRequestResponse>({ totalMatches: 0, products: [] });

  const searchPagination = useRecoilValue(searchPaginationAtom);

  const searchResultsLoadable = useRecoilValue(shownSearchResultsSelector);

  useEffect(() => {
    // TODO needed due to database issues, should check if still needed
    const uniqueProducts = searchResultsLoadable.products.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.gtin === value.gtin)
    );

    if (searchPagination === 1 && searchResultsLoadable) {
      setPreviousResults({
        ...searchResultsLoadable,
        products: uniqueProducts,
      });
    } else {
      setPreviousResults((prev) => ({
        totalMatches: searchResultsLoadable.totalMatches,
        products: [...prev.products, ...uniqueProducts],
      }));
    }
  }, [searchPagination, setPreviousResults, searchResultsLoadable]);

  return previousResults;
}
