import { selectorFamily } from "recoil";
import { deltaE00, hex2lab } from "../../v2/utils/colorCalculations";
import { allProductsAtom } from "../atoms/allProductsAtom";
import { TProduct } from "../../types";
import { matchFilterAtom } from "../atoms";
import { captureException } from "@sentry/react";

export const getColorMatchesSelector = selectorFamily({
  key: "getColorMatchesSelector",
  get:
    ({
      selectedColor,
      typeFilter,
    }: {
      selectedColor: string | null;
      typeFilter: string | null;
    }) =>
    async ({ get }: { get: any }) => {
      if (!selectedColor) return [];

      if (!typeFilter) return [];

      const selectedColorLab = hex2lab(`#${selectedColor}`);

      const allProducts = get(allProductsAtom);

      const productsWithLab = allProducts
        .filter(
          (product: TProduct) => product.type.toLowerCase() === typeFilter
        )
        .map((product: TProduct) => {
          if (!product.colorhex) return { ...product, distance: 10000 };

          const productLab = hex2lab(product.colorhex);

          const distance = deltaE00(
            selectedColorLab[0],
            selectedColorLab[1],
            selectedColorLab[2],
            productLab[0],
            productLab[1],
            productLab[2]
          );
          return {
            ...product,
            distance,
          };
        })
        .sort((a: any, b: any) => a.distance - b.distance);

      const productSubSet = productsWithLab.slice(0, 10);

      const bestPriceOrMatchFilter = get(matchFilterAtom);

      return bestPriceOrMatchFilter === "price"
        ? productSubSet.sort((a: any, b: any) => a.price_de - b.price_de)
        : productSubSet.sort((a: any, b: any) => a.distance - b.distance);
    },
});
