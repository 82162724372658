import { selectorFamily } from "recoil";
import { searchSuggestionsAtom } from "../atoms/searchSuggestionsAtom";

export const searchSuggestionsSelector = selectorFamily({
  key: "searchSuggestionsSelector",
  get:
    (searchQuery: string) =>
    ({ get }) => {
      const suggestions = get(searchSuggestionsAtom);

      const lowerCaseQuery = searchQuery.toLowerCase();

      // if there are no suggestions for this category return empty array
      if (
        !suggestions ||
        suggestions?.length === 0 ||
        lowerCaseQuery.length === 0
      )
        return [];

      const results = suggestions.filter((suggestion) =>
        suggestion.toLowerCase().includes(lowerCaseQuery)
      );

      return results.slice(0, 10);
    },
});
