import { Dialog } from "@headlessui/react";
import { BottomModal } from "../../components/modals/BottomModal";
import { Button } from "../../components/buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// TODO: Replace with acctual loading indicator
export const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col   px-5 pb-11 pt-4">
        <div className="flex w-full justify-center py-10">
          <img
            className={`h-10 w-full object-contain`}
            src="/matched_logo_black.png"
            alt="logo"
          />
        </div>
        <BottomModal
          isOpen={true}
          onClose={() => false}
          testId="forgot-password-bottom-modal"
        >
          <Dialog.Panel className="w-full rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
            <div className="mt-8 mb-5 flex w-full items-center justify-center ">
              <h2 className="font-circular-medium text-2xl font-medium text-white">
                {t("NOT_FOUND_PAGE.TITLE")}
              </h2>
            </div>
            <div className="flex w-full items-center justify-center">
              <span className="mb-6 font-circular-book text-base text-white">
                {t("NOT_FOUND_PAGE.SUBTITLE")}
              </span>
            </div>

            <div className="mb-5">
              <Button
                type="button"
                text={t("NOT_FOUND_PAGE.BUTTON_LABEL")}
                styleType="secondary"
                onClick={() => navigate("/")}
              />
            </div>
          </Dialog.Panel>
        </BottomModal>
      </div>
    </div>
  );
};
