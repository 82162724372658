import { useRecoilValue } from "recoil";
import { matchingDupesSelector } from "../../../../state/selectors/matchingDupesSelector";
import { ProductCircle } from "./ProductCircle";
import { selectedProductToDupeSelector } from "../../../../state/selectors/selectedProductToDupeSelector";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { TDupeProduct } from "../../../../types";
import { truncateString } from "../../../utils/truncateString";

export const MatchedCosmos = () => {
  const { matchGtin } = useParams();
  const matches = useRecoilValue(matchingDupesSelector(undefined));

  const selectedProductToDupe = useRecoilValue(
    selectedProductToDupeSelector(null)
  );

  if (matches === null || matches.length < 8 || !matchGtin) return <></>;

  const webpUrl = useMemo(
    () => selectedProductToDupe?.imagefront.replace(".png", ".webp"),
    [selectedProductToDupe?.imagefront]
  );

  const sortedMatches = sortMatchesAndAddSelectedDupeInCosmos(
    matches,
    matchGtin
  );

  const brandname = truncateString(selectedProductToDupe?.brand, 9);

  return (
    <div className="flex w-full justify-center" data-cy="match-cosmos">
      <div className="relative mt-14 mb-14 flex w-80 items-center justify-center ">
        <div className="shadow-circle-2 flex h-72 w-72 items-center justify-center rounded-full shadow-circle-1">
          <div className="flex flex-col items-center justify-center">
            <img
              alt="product"
              src={`${import.meta.env.VITE_CDN_URL}/${webpUrl}`}
              className="h-20 w-20 object-contain "
            />
            <div className="flex flex-col items-center justify-center">
              <span className="text-sx font-circular-medium uppercase">
                {brandname}
              </span>
            </div>
          </div>
        </div>
        <div className="absolute -top-10">
          <ProductCircle
            isSelected={sortedMatches[10].gtin_twinproduct === matchGtin}
            product={sortedMatches[10]}
          />
        </div>
        <div className="absolute top-0 right-5">
          <ProductCircle
            isSelected={sortedMatches[3].gtin_twinproduct === matchGtin}
            product={sortedMatches[3]}
          />
        </div>
        <div className="absolute -right-5">
          <ProductCircle
            isSelected={sortedMatches[4].gtin_twinproduct === matchGtin}
            product={sortedMatches[4]}
          />
        </div>
        <div className="absolute  right-5 bottom-0">
          <ProductCircle
            isSelected={sortedMatches[5].gtin_twinproduct === matchGtin}
            product={sortedMatches[5]}
          />
        </div>
        <div className="absolute -bottom-10 ">
          <ProductCircle
            isSelected={sortedMatches[6].gtin_twinproduct === matchGtin}
            product={sortedMatches[6]}
          />
        </div>
        <div className="absolute left-5 -bottom-0 ">
          <ProductCircle
            isSelected={sortedMatches[7].gtin_twinproduct === matchGtin}
            product={sortedMatches[7]}
          />
        </div>
        <div className="absolute -left-5 ">
          <ProductCircle
            isSelected={sortedMatches[8].gtin_twinproduct === matchGtin}
            product={sortedMatches[8]}
          />
        </div>
        <div className="absolute top-0 left-5">
          <ProductCircle
            isSelected={sortedMatches[9].gtin_twinproduct === matchGtin}
            product={sortedMatches[9]}
          />
        </div>
      </div>
    </div>
  );
};

// If selected dupe is not in the top 8 add it as the last one
function sortMatchesAndAddSelectedDupeInCosmos(
  matches: TDupeProduct[],
  matchGtin: string
) {
  const sortedMatches = matches.slice().sort((a, b) => {
    return Number(a.sim_total) - Number(b.sim_total);
  });

  const selectedDupeIndex = sortedMatches.findIndex(
    (dupe: TDupeProduct) => dupe.gtin_twinproduct === matchGtin
  );
  if (selectedDupeIndex < 2) {
    sortedMatches[3] = sortedMatches[selectedDupeIndex];
  }

  return sortedMatches;
}
