import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ERoutePaths } from "../../../../types";
import { classNames } from "../../../../shared";
import { t } from "i18next";
import { useElementOnScreen } from "../../../../hooks/useElementOnScreen";
import { trackContentClicked } from "../../../../Tracking";

type TTutorial = {
  link: string;
  name: string;
  imgSrc: string;
  title: string;
  description: string;
  textColor?: string;
};

const tutorials: TTutorial[] = [
  {
    link: ERoutePaths.COLORMATCH,
    name: "FEATURE_FILTER_LABEL_BEST_CHANEL",
    title: "TUTORIAL_COLOR_MATCH_TITLE",
    imgSrc: "/v2/images/yellow-shoes.jpg",
    description: "TUTORIAL_COLOR_MATCH_DESCRIPTION",
    textColor: "text-black",
  },
  {
    link: ERoutePaths.INSTOREMATCH,
    name: "FEATURE_FILTER_LABEL_BEST_CHANEL",
    title: "TUTORIAL_INSTORE_MATCH_TITLE",
    imgSrc: "/v2/images/scanner.jpg",
    description: "TUTORIAL_INSTORE_MATCH_DESCRIPTION",
    textColor: "text-white",
  },
];

export const TutorialSection: React.FC = () => {
  const { t } = useTranslation();
  const [currentVisible, setCurrentVisible] = useState(0);

  return (
    <div className="mt-8 h-128 w-full">
      <div className="px-6">
        <h2 className="font-circular-book text-base uppercase">
          {t("HOME_PAGE.TUTORIAL_SECTION_TITLE")}
        </h2>
        <p className="font-ivypresto-regular text-2xl">
          {t("HOME_PAGE.TUTORIAL_SECTION_SUBTITLE")}
        </p>
      </div>
      <div className="scrollbar-hide  bottom-0  mt-7 flex w-full  overflow-auto ">
        {tutorials.map((tutorial, index) => (
          <TutorialItem
            index={index}
            tutorial={tutorial}
            key={index}
            onBecomeVisible={() => setCurrentVisible(index)}
          />
        ))}
      </div>
      <div className="flex h-10 w-full items-center justify-center">
        <div className="flex w-10 justify-around ">
          {tutorials.map((img, i) => (
            <div
              key={i}
              className={classNames(
                currentVisible !== i
                  ? "w-2 opacity-60 "
                  : "w-6 scale-y-110 opacity-100",
                "h-2 transform rounded-full bg-gray-300 duration-200 ease-in-out "
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const TutorialItem = ({
  tutorial,
  onBecomeVisible,
  index,
}: {
  index: number;
  tutorial: TTutorial;
  onBecomeVisible: () => void;
}) => {
  const [imageRef, isVisible] = useElementOnScreen({
    threshold: 0.6,
    reappear: true,
  });

  const onClicked = () => {
    trackContentClicked(tutorial.description, tutorial.title, index);
  };

  useEffect(() => {
    if (isVisible) {
      onBecomeVisible();
    }
  }, [isVisible]);
  return (
    <Link
      className="relative w-full shrink-0 snap-center "
      to={tutorial.link}
      onClick={onClicked}
    >
      <img
        alt={tutorial.name}
        src={tutorial.imgSrc}
        className="h-96 w-full object-cover lg:object-cover"
      />

      <div
        ref={imageRef}
        className="absolute bottom-20 left-9 z-10 flex h-20 w-3/4 flex-col gap-2"
      >
        <div className="flex gap-5">
          <div className="z-10 h-fit bg-black p-2 font-circular-medium text-sm leading-5 text-white">
            {t(`HOME_PAGE.${tutorial.title}`)}
          </div>
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-black">
            <img
              src="/v2/icons/matched-icon.png"
              className="h-6 w-6 invert"
              alt="logo"
            ></img>
          </div>
        </div>
        <div>
          <span
            className={classNames(
              "font-circular-book text-lg leading-7",
              tutorial.textColor || ""
            )}
          >
            {t(`HOME_PAGE.${tutorial.description}`)}
          </span>
        </div>
      </div>
    </Link>
  );
};
