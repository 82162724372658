import { useMemo } from "react";
import { TDupeProduct } from "../../../../types";
import { selectedProductToDupeSelector } from "../../../../state/selectors/selectedProductToDupeSelector";
import { useRecoilValue } from "recoil";
import { truncateString } from "../../../utils/truncateString";
import { classNames } from "../../../../shared";
import { Link } from "react-router-dom";

export const ProductCircle = ({
  isSelected = false,
  extraClassNames = "h-[70px] w-[70px]",
  product,
}: {
  isSelected?: boolean;
  extraClassNames?: string;
  product: TDupeProduct;
}) => {
  const webpUrl = useMemo(
    () => product.data.imagefront.replace(".png", ".webp"),
    [product.data.imagefront]
  );

  const brandname = truncateString(product.data.brand, 9);

  return (
    <Link
      to={`/de/${product.gtin_originalproduct}/match-details/${product.gtin_twinproduct}`}
      className="relative inline-flex items-center justify-center overflow-visible rounded-full bg-white "
    >
      <svg
        className={extraClassNames}
        viewBox="0 0 36 38"
        stroke="currentColor"
        style={{ transform: "rotate(0deg)" }}
        fill="none"
      >
        <defs>
          <linearGradient
            id="Gradient3"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
            spreadMethod="pad"
          >
            <stop offset="0%" stopColor="#7D7D7D" stopOpacity={"100%"} />
            <stop offset="100%" stopColor="#7D7D7D" stopOpacity={"0%"} />
          </linearGradient>
        </defs>
        <path
          className="circle-bg stroke-gray-200 stroke-1"
          strokeDasharray={`${1.1 * 100}, 100`}
          d="M18 0.8 a 15.9155 15.9155 0 0 1 0 34.831 a 15.9155 15.9155 0 0 1 0 -34.831"
        />
        <path
          className={"circle stroke-2"}
          strokeLinecap="round"
          stroke={isSelected ? `${product.data.colorhex}` : "url(#Gradient3)"}
          strokeDasharray={`${1.1 * parseFloat(product.sim_total)}, 100`}
          d="M18 0.8 a 15.9155 15.9155 0 0 1 0 34.831 a 15.9155 15.9155 0 0 1 0 -34.831"
        />
      </svg>

      <span className="absolute ">
        <img
          className="h-10 w-10 object-contain object-center"
          src={`${import.meta.env.VITE_CDN_URL}/${webpUrl}`}
          alt={product.gtin_twinproduct}
        />
      </span>
      <div className="absolute -bottom-8 flex  w-20 flex-col items-center justify-center ">
        <span className=" leading- items-center justify-center break-normal   font-circular-medium text-xs uppercase">
          {brandname}
        </span>
        <span className=" font-ivypresto-regular text-xs leading-3">
          {product.sim_total}%
        </span>
      </div>
    </Link>
  );
};
