import { TProduct } from "../types";
import { getRequest } from "./getRequest";

export const getSearchSuggestionsRequest = async (): Promise<string[]> => {
  try {
    const searchUrl = `${import.meta.env.VITE_BACKEND_URL}/autoSuggestionList`;

    const searchSuggestions = await getRequest(searchUrl);

    if (!searchSuggestions || Object.keys(searchSuggestions).length === 0) {
      return [];
    }

    return searchSuggestions;
  } catch (error) {
    console.log(error); // TODO show this to the user
    return [];
  }
};
