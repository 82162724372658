import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { trackProductImpression } from "../Tracking";
import { TDupeProduct } from "../types";

export function useTrackInstoreMatch(matchProducts: TDupeProduct[] | null) {
  const location = useLocation();
  const previousPathName = useRef<string | null>(null);

  useEffect(() => {
    if (
      previousPathName.current !== location.pathname &&
      matchProducts &&
      matchProducts.length > 0
    ) {
      trackProductImpression(
        matchProducts.map((dupe) => dupe.data),
        "Match-Page",
        "match_list"
      );
      previousPathName.current = location.pathname;
    }
  }, [location, matchProducts]);
}
