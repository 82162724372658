import { useRecoilState } from "recoil";
import { globalErrorAtom } from "../../state/atoms";
import ErrorDialog from "./ErrorDialog";

export const GlobalErrorDialog = () => {
  const [globalError, setGlobalError] = useRecoilState(globalErrorAtom);

  if (!globalError) {
    return null;
  }

  return (
    <ErrorDialog
      error={globalError}
      title={globalError.name}
      description={globalError.message}
      buttonLabel="OK"
      onClose={() => setGlobalError(null)}
    />
  );
};
