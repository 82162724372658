import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { createRef, useEffect, useState } from "react";
// import { trackImageSlideChange } from "../../Tracking";
import { classNames } from "../../shared";
import { useElementOnScreen } from "../../hooks/useElementOnScreen";
import { useLocation } from "react-router-dom";
type TImageCarousellProps = {
  images: string[];
};

type TImageRefs = {
  [key: string]: React.RefObject<HTMLImageElement>;
};

export const ImageCarousell = ({
  images = [],
}: TImageCarousellProps): JSX.Element => {
  // We will start by storing the index of the current image in the state.
  const [currentImage, setCurrentImage] = useState(0);
  const location = useLocation();

  // We are using react ref to 'tag' each of the images. Below will create an array of
  // objects with numbered keys. We will use those numbers (i) later to access a ref of a
  // specific image in this array.
  const refs = images.reduce((acc: TImageRefs, val, i) => {
    acc[i] = createRef();
    return acc;
  }, {});

  useEffect(() => {
    setCurrentImage(0);
    scrollToImage(0);
  }, [location, setCurrentImage]);

  const scrollToImage = (i: number) => {
    // TODO DUP-232 remove if confirmed by max
    // trackImageSlideChange(i);
    // First let's set the index of the image we want to see next
    setCurrentImage(i);
    // Now, this is where the magic happens. We 'tagged' each one of the images with a ref,
    // we can then use built-in scrollIntoView API to do eaxactly what it says on the box - scroll it into
    // your current view! To do so we pass an index of the image, which is then use to identify our current
    // image's ref in 'refs' array above.
    refs[i]?.current?.scrollIntoView({
      //     Defines the transition animation.
      behavior: "smooth",
      //      Defines vertical alignment.
      block: "nearest",
      //      Defines horizontal alignment.
      inline: "start",
    });
  };

  // Some validation for checking the array length could be added if needed
  const totalImages = images.length;

  // Below functions will assure that after last image we'll scroll back to the start,
  // or another way round - first to last in previousImage method.
  const nextImage = () => {
    if (currentImage >= totalImages - 1) {
      scrollToImage(0);
    } else {
      scrollToImage(currentImage + 1);
    }
  };

  const previousImage = () => {
    if (currentImage === 0) {
      scrollToImage(totalImages - 1);
    } else {
      scrollToImage(currentImage - 1);
    }
  };

  // Tailwind styles. Most importantly notice position absolute, this will sit relative to the carousel's outer div.
  const arrowStyle =
    "absolute text-white text-2xl z-10  h-10 w-10 rounded-full opacity-75 flex items-center justify-center";

  // Let's create dynamic buttons. It can be either left or right. Using
  // isLeft boolean we can determine which side we'll be rendering our button
  // as well as change its position and content.
  const sliderControl = (isLeft?: boolean) => (
    <button
      type="button"
      onClick={isLeft ? previousImage : nextImage}
      className={`${arrowStyle} ${isLeft ? "left-2" : "right-2"}`}
      style={{ top: "40%" }}
    >
      <span role="img" aria-label={`Arrow ${isLeft ? "left" : "right"}`}>
        {isLeft ? (
          <ChevronLeftIcon className="h-10 w-10 text-gray-300" />
        ) : (
          <ChevronRightIcon className="h-10 w-10 text-gray-300" />
        )}
      </span>
    </button>
  );

  return (
    // Images are placed using inline flex. We then wrap an image in a div
    // with flex-shrink-0 to stop it from 'shrinking' to fit the outer div.
    // Finally the image itself will be 100% of a parent div. Outer div is
    // set with position relative, so we can place our cotrol buttons using
    // absolute positioning on each side of the image.
    <div className="flex h-72 w-full items-center justify-center ">
      <div className="relative flex h-full w-full justify-center  ">
        <div className="carousel scrollbar-hide inline-flex w-full snap-x snap-mandatory overflow-scroll  pb-4">
          <div className="hidden  md:block">{sliderControl(true)}</div>
          {images.map((img, i) => (
            // <div
            //   className="w-full flex-shrink-0 snap-center "
            //   key={img}
            //   ref={refs[i]}
            // >
            //   <img
            //     src={img}
            //     className="h-64 w-full object-contain"
            //     alt="Product Detail"
            //   />
            // </div>
            <ProductImage
              key={i}
              img={img}
              containerRef={refs[i]}
              onBecomeVisible={() => setCurrentImage(i)}
            />
          ))}
          <div className="hidden md:block">{sliderControl()}</div>
        </div>
        <div className="absolute bottom-0  left-1/2 flex w-20 -translate-x-1/2 justify-around ">
          {images.map((img, i) => (
            <div
              key={i}
              className={classNames(
                currentImage !== i
                  ? "w-2 opacity-60 "
                  : "w-6 scale-y-110 opacity-100",
                "h-2 transform rounded-full bg-gray-300 duration-200 ease-in-out "
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type TProductImageProps = {
  img: string;
  containerRef: React.RefObject<HTMLDivElement>;
  onBecomeVisible: () => void;
};

const ProductImage = ({
  img,
  containerRef,
  onBecomeVisible,
}: TProductImageProps) => {
  const [imageRef, isVisible] = useElementOnScreen({
    threshold: 0.6,
    reappear: true,
  });

  useEffect(() => {
    if (isVisible) {
      onBecomeVisible();
    }
  }, [isVisible]);

  return (
    <div
      className="w-full flex-shrink-0 snap-center "
      key={img}
      ref={containerRef}
    >
      <div ref={imageRef}>
        <img
          src={img}
          className="h-64 w-full object-contain"
          alt="Product Detail"
        />
      </div>
    </div>
  );
};

// TODO refactor this component and enable full size image view / enable swiping
