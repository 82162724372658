import { connectAuthEmulator, getAuth } from "firebase/auth";
import { app } from "../firebaseInit";

const auth = getAuth(app);

if (import.meta.env.VITE_IS_TEST_MODE) {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
}

export { auth };
// Initialize Firebase Authentication and get a reference to the service
