import { selector } from "recoil";
import { searchProductsRequest } from "../../api";
import {
  categoryFilterAtom,
  searchPaginationAtom,
  searchStringFilterAtom,
  searchTypeFiltersAtom,
} from "../atoms";
import { trackProductFilterComplete } from "../../Tracking";

export const shownSearchResultsSelector = selector({
  key: "shownSearchResultsSelector",
  get: async ({ get }) => {
    const searchStringFilter = get(searchStringFilterAtom);
    const searchTypeFilters = get(searchTypeFiltersAtom);
    const categoryFilter = get(categoryFilterAtom);
    const searchPagination = get(searchPaginationAtom);

    const result = await searchProductsRequest(
      searchStringFilter,
      categoryFilter,
      20,
      searchPagination,
      searchTypeFilters.filter(
        (filter) => filter.selected && filter.selectedValue !== null
      )
    );

    // TODO: DUP-232 check if event is acctually needed since site_search_complete already track them
    const filtersToTrack: string[] = searchTypeFilters
      .filter((filter) => filter.selected)
      .map((filter) => {
        if (filter.type === "dropdown" && filter.selectedValue) {
          return filter.selectedValue;
        }
        // @ts-ignore
        return filter.value;
      });

    if (filtersToTrack.length > 0) {
      trackProductFilterComplete(String(result.totalMatches), filtersToTrack);
    }

    return result;
  },
});
