import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { trackProductImpression } from "../Tracking";
import { TProduct } from "../types";

export function useTrackProductImpression(products: TProduct[] | null) {
  const location = useLocation();
  const productGtinsBefore = useRef<string | null>(null);

  useEffect(() => {
    if (!products || products.length < 1) return;
    const currentProductString = products
      .map((product) => product.gtin)
      .join(",");
    if (productGtinsBefore.current !== currentProductString) {
      trackProductImpression(products, "Product Search", "search_results");
      productGtinsBefore.current = currentProductString;
    }
  }, [location, products, productGtinsBefore]);
}
