import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { BottomModal } from "../../components/modals/BottomModal";
import { FormProvider, useForm } from "react-hook-form";
import { EmailInputLabled } from "../../components/inputs/EmailInputLabled";
import { LabledInput } from "../../components/inputs/LabledInput";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { GoogleLogo } from "../../../components";
import { LabeledLineDivider } from "../../components/divider/LabledLineDivider";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { loginWithPassword } from "../../../thridparty/firebase/auth/loginWithPassword";
import { trackAccountLogin } from "../../../Tracking";
import { loginWithGoogle } from "../../../thridparty/firebase/auth/loginWithGoogle";
import { useSetRecoilState } from "recoil";
import { userAtom } from "../../../state/atoms/userAtom";
import { EErrorType, ELoadedLevel, TError } from "../../../types";
import { deepCopy } from "../../../shared";
import { convertError } from "../../../shared/utils/convertError/convertError";
import { useTranslation } from "react-i18next";

type Inputs = {
  email: string;
  password: string;
};

export const LoginModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const methods = useForm<Inputs>();
  const setUser = useSetRecoilState(userAtom);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const onSubmit = async ({ email, password }: Inputs) => {
    try {
      setLoading(true);
      const user = await loginWithPassword(email, password);

      if (!user) {
        throw new Error("Login failed");
      }

      // if (!user.emailVerified) {
      //   throw "VERIFY_EMAIL";
      // }

      trackAccountLogin(user.uid);
      setUser({
        status: ELoadedLevel.LOADED,
        user: deepCopy(user),
      });
      onClose();
      navigate("/");
      // navigate(0);
    } catch (error) {
      handleLoginError(convertError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleLoginError = (error: TError) => {
    setError("root.serverError", {
      type: error.type,
      message: error.userMessage,
    });
  };

  const onGoogleLogin = async () => {
    try {
      const user = await loginWithGoogle();

      if (user?.uid) {
        trackAccountLogin(user.uid);
      }

      if (!user) {
        throw new Error("Google Login failed");
      }
      setUser({
        status: ELoadedLevel.LOADED,
        user: deepCopy(user),
      });
      onClose();
      navigate("/");
      navigate(0);
    } catch (error) {
      handleLoginError(convertError(error));
    }
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose} testId="login-bottom-modal">
      <Dialog.Panel className="w-screen rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
        <div className="absolute right-0 top-7  pr-4 pt-4 sm:block">
          <button
            data-cy="close-button"
            type="button"
            className="rounded-md   text-white hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mt-6 mb-8 flex w-full items-center justify-center ">
              <h2 className="font-circular-medium text-2xl font-medium text-white">
                {t("LOGIN_PAGE.TITLE")}
              </h2>
            </div>
            <div className="mb-5">
              <EmailInputLabled onClick={() => clearErrors()} />
            </div>
            <div className="mb-5">
              <LabledInput
                labelText={t("LOGIN_PAGE.PASSWORD_LABEL")}
                name="password"
                type="password"
                onClick={() => clearErrors()}
                rules={{ required: true }}
              />
              <div className="mt-1 flex flex-col items-end">
                <Link
                  data-cy="forgot-password-link"
                  to={"/de/login?modal=forgot"}
                  className="font-circle-book text-sm text-white"
                >
                  {t("LOGIN_PAGE.FORGOT_PASSWORD_LABEL")}
                </Link>
              </div>
            </div>

            {errors?.root?.serverError.type === EErrorType.FirebaseError && (
              <div className="my-4" data-cy="login-error-message">
                <p className=" font-circular-book text-red-400 ">
                  {errors?.root?.serverError.message}
                </p>
              </div>
            )}

            {errors?.root?.serverError.type === EErrorType.Exception && (
              <div className="my-4" data-cy="login-error-message">
                <p className=" font-circular-book text-red-400 ">
                  {errors?.root?.serverError.message}
                </p>
              </div>
            )}

            <div className="mb-5">
              <Button
                type="submit"
                testId="login-button"
                text={t("LOGIN_PAGE.BUTTON_LABEL")}
                styleType="secondary"
                loading={loading}
              />
            </div>
            <div className="mb-5">
              <LabeledLineDivider label={t("LOGIN_PAGE.OR_LABEL")} />
            </div>
            <div className="mb-5">
              <div>
                <button
                  onClick={onGoogleLogin}
                  className=" inline-flex w-full cursor-pointer justify-center rounded-full    py-2 px-4 text-sm font-medium text-subtle-grey hover:opacity-80"
                >
                  <span className="sr-only">Sign in with Google</span>
                  <GoogleLogo />
                </button>
              </div>
            </div>
            <div>
              <span className="font-cirular-book text-sm text-white">
                {t("LOGIN_PAGE.REGISTER_LABEL_ONE")}{" "}
                <Link
                  data-cy="register-link"
                  to={"/de/login?modal=register"}
                  className="text-sm font-bold text-white"
                >
                  {t("LOGIN_PAGE.REGISTER_LABEL_TWO")}
                </Link>
              </span>
            </div>
          </form>
        </FormProvider>
      </Dialog.Panel>
    </BottomModal>
  );
};
