export const CategoryColorMatchPickerPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hex } = useParams();

  return (
    <div
      className={
        "relative flex h-screen w-screen flex-col items-center  justify-start pt-2"
      }
      style={{
        backgroundColor: `#${hex}`,
      }}
    >
      <div className="mt-5 flex w-full">
        <div className="w-1/3">
          <button
            type="button"
            className="top-4 left-4 z-10 rounded-full  p-4 focus:outline-none"
            onClick={() => navigate(`${ERoutePaths.COLORMATCH}?mode=color`)}
          >
            <span className="sr-only">Close panel</span>
            <ChevronLeftIcon
              className="h-6 w-6 text-white"
              aria-hidden="true"
            />
          </button>
        </div>
        <div className="flex w-1/3 items-center justify-center  font-circular-book text-xl text-white">
          Color Match
        </div>
        <div className="flex w-1/3 justify-end">
          <button
            type="button"
            className=" top-4  right-4 z-10 rounded-full  p-4 focus:outline-none"
            onClick={() => navigate("/")}
          >
            <span className="sr-only">Close panel</span>
            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-9 flex w-full items-center  justify-center  px-16">
        <h1 className="text-center font-ivypresto-half-bold text-2xl text-white">
          {t("COLOR_MATCH_CATEGORY_PAGE.TITLE")}
        </h1>
      </div>
      <div className="mt-12 flex w-full justify-center">
        <div className="grid w-64 grid-cols-2 gap-4 pl-2">
          {featureOptions.map((feature, index) => (
            <Link
              key={index}
              data-cy={`feature-filter-${feature.name.toLowerCase()}`}
              className="flex h-24 w-28 shrink-0 flex-col items-center justify-center rounded-lg bg-black p-4"
              to={`/de/color-match/${feature.value.toLowerCase()}/${hex}`}
            >
              <img
                alt={feature.name}
                src={feature.imgSrc}
                className="h-9 w-9 rounded-lg object-contain "
              />
              <div className="mt-2 flex flex-col items-center justify-center">
                <span className="font-circular-book text-xs uppercase text-white">
                  {t(feature.name)}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { ERoutePaths } from "../../../../types";

const featureOptions = [
  {
    imgSrc: "/v2/icons/nailpolish-catgeory-icon.png",
    value: "Nail Polish",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_ONE",
    id: 1,
  },
  {
    imgSrc: "/v2/icons/lipstick-category-icon.png",
    value: "Lipstick",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_TWO",
    id: 2,
  },
  {
    imgSrc: "/v2/icons/lipgloss-category-icon.png",
    value: "Lipgloss",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_THREE",
    id: 3,
  },
  {
    imgSrc: "/v2/icons/lipliner-category-icon.png",
    value: "Lip Pencil",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_FOUR",
    id: 4,
  },
];
