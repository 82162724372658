import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { getInfoPages } from "./configs/infoPages";
import { useTranslation } from "react-i18next";
import { TInfoPage } from "../../../types";
import { randomId } from "../../utils/randomId";

export const InfoPage = () => {
  const location = useLocation();

  const { i18n } = useTranslation();

  const _infoPages: TInfoPage[] = getInfoPages(i18n.language);

  const page = _infoPages.find(
    (page: any) => page.urlKey === location.pathname
  );

  const [searchParams] = useSearchParams();
  const jumpTo = searchParams.get("jumpTo");
  const naviagte = useNavigate();

  useEffect(() => {
    if (!jumpTo) {
      window.scrollTo(0, 0);
      return;
    }
    const timeOut = setTimeout(() => {
      const ele = document.getElementById(jumpTo);
      if (!ele) return;
      window.scrollTo(ele.offsetLeft, ele.offsetTop);
    }, 100);

    return () => {
      clearTimeout(timeOut);
    };
  }, [jumpTo]);

  const onClickBack = () => {
    naviagte(-1);
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col  px-5 pb-11 pt-12">
        <div className="relative mb-10 flex w-full items-center justify-between  bg-white">
          <button
            onClick={onClickBack}
            className="absolute top-1/4 left-0"
            data-cy="info-page-back-button"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </button>
          <div className="flex w-full items-center justify-center  ">
            <h1 className=" font-ivypresto-regular text-3xl">
              {page?.pageTitle}
            </h1>
          </div>
        </div>

        {page?.sections.map((section, index) => (
          <div className="w-full bg-no-repeat" key={index} id={section.id}>
            <h2 className="mb-2 font-circular-medium text-xl ">
              {section.title}
            </h2>
            {section.paragraphs.map((paragraph, index) => (
              <p
                key={`p-${index}`}
                className=" mb-4 w-full break-words text-left  font-circular-book text-base text-gray-300"
                dangerouslySetInnerHTML={{ __html: paragraph }}
              ></p>
            ))}

            {section.tables?.map((table, index) => (
              <div
                key={`${index}-${randomId()}`}
                className="mb-8 w-96 overflow-x-scroll "
              >
                <h2 className="mb-2 font-circular-medium text-xl ">
                  {table.title}
                </h2>
                <table className="">
                  <thead>
                    <tr>
                      {table.headerColumns.map((header, index) => (
                        <th
                          key={`th-${index}-${randomId()}`}
                          className="border px-4 py-2 text-sm"
                        >
                          {header}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {table.rows.map((row, index) => (
                      <tr key={`tr-${index}-${randomId()}`}>
                        {table.headerColumns.map((key) => (
                          <td
                            key={`td-${index}-${randomId()}`}
                            className="border px-4 py-2 text-sm"
                          >
                            {row[key.toLowerCase()]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
