import React from "react";

interface HeroImageSectionProps {
  title: string;
  image: string;
}

const HeroImageSection: React.FC<HeroImageSectionProps> = ({
  title,
  image,
}) => {
  return (
    <section className="relative h-96 w-full overflow-hidden ">
      <img
        alt={title}
        src={image}
        className="h-96 w-full object-cover object-center"
      />
      <div className="absolute inset-0 flex items-center justify-center px-6">
        <h1 className="text-center font-ivypresto-regular text-4xl leading-9 text-white">
          {title}
        </h1>
      </div>
    </section>
  );
};

export default HeroImageSection;
