import React from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { searchTypeFiltersAtom } from "../../../../state/atoms";
import { useTranslation } from "react-i18next";

const featureOptions = [
  {
    imgSrc: "/v2/icons/nailpolish-catgeory-icon.png",
    value: "Nail Polish",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_ONE",
    id: 1,
  },
  {
    imgSrc: "/v2/icons/lipstick-category-icon.png",
    value: "Lipstick",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_TWO",
    id: 2,
  },
  {
    imgSrc: "/v2/icons/lipgloss-category-icon.png",
    value: "Lipgloss",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_THREE",
    id: 3,
  },
  {
    imgSrc: "/v2/icons/lipliner-category-icon.png",
    value: "Lip Pencil",
    name: "HOME_PAGE.CATEGORY_FILTER_LABEL_FOUR",
    id: 4,
  },
];

export const CategoryDiscoverSection: React.FC = () => {
  const setSearchTypeFilters = useSetRecoilState(searchTypeFiltersAtom);

  const { t } = useTranslation();

  const setSearchFilter = (clickedFilterId: number, filterValue: string) => {
    setSearchTypeFilters((prev) =>
      prev.map((filter) => ({
        ...filter,
        selected: filter.id === clickedFilterId ? true : false,
        selectedValue: filter.id === clickedFilterId ? filterValue : null,
      }))
    );
  };

  return (
    <div className="w-full " data-cy="category-filters">
      <div>
        <h2 className="font-ivypresto-regular text-2xl">
          {t("HOME_PAGE.CATEGORY_FILTER_SECTION_TITLE")}
        </h2>
      </div>
      <div className="scrollbar-hide mt-5 flex w-full overflow-auto">
        {featureOptions.map((feature, index) => (
          <Link
            key={index}
            data-cy={`feature-filter-${feature.name.toLowerCase()}`}
            className="mr-4 flex h-24 w-28 shrink-0 flex-col items-center justify-center rounded-lg bg-black p-4"
            onClick={() => setSearchFilter(feature.id, feature.value)}
            to={"/de/suche"}
          >
            <img
              alt={feature.name}
              src={feature.imgSrc}
              className="h-9 w-9 rounded-lg object-contain "
            />
            <div className="mt-2 flex flex-col items-center justify-center">
              <span className="font-circular-book text-xs uppercase text-white">
                {t(feature.name)}
              </span>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};
