export const MatchSliderLoadingSkeleton = () => {
  return (
    <div className="relative h-96 w-full overflow-hidden ">
      <div className="absolute left-6 flex h-96 w-72 flex-col items-start justify-start rounded-lg bg-white">
        <div className="h-52 w-full animate-pulse rounded-t-lg bg-gray-200" />
        <div className="relative flex w-full flex-col items-start justify-start p-5">
          <div className="absolute top-7 h-6 w-20 animate-pulse rounded-md bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-16 h-4 w-40  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-24 h-4 w-28  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-32 h-4 w-28  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
        </div>
      </div>
      <div className="absolute left-[328px] flex h-96 w-72 flex-col items-start justify-start rounded-lg bg-white">
        <div className="h-52 w-full animate-pulse rounded-t-lg bg-gray-200" />
        <div className="relative flex w-full flex-col items-start justify-start p-5">
          <div className="absolute top-7 h-6 w-20 animate-pulse rounded-md bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-16 h-4 w-40  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-24 h-4 w-28  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
          <div className="absolute top-32 h-4 w-28  animate-pulse bg-gray-200 font-ivypresto-regular text-3xl leading-9"></div>
        </div>
      </div>
    </div>
  );
};
