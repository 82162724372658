import { useEffect } from "react";

export const usePreventScrollOnTouch = (
  canvasRef: React.RefObject<HTMLCanvasElement>
) => {
  useEffect(() => {
    function stopTouchScrolling(canvas: any) {
      // Prevent scrolling when touching the canvas
      document.body.addEventListener(
        "touchstart",
        function (e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
      document.body.addEventListener(
        "touchend",
        function (e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
      document.body.addEventListener(
        "touchmove",
        function (e) {
          if (e.target == canvas) {
            e.preventDefault();
          }
        },
        { passive: false }
      );
    }

    stopTouchScrolling(canvasRef.current);
  }, []);
};
