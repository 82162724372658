import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import { Outlet, useNavigate } from "react-router-dom";

// import { BarcodeScanner } from "./components/BarcodeScanner";
// import { ColorPickerScanner } from "./components/ColorPickerScanner/ColorPickerScanner";
// import { ScanTypeButton } from "./components/ScanTypeButton";
import ErrorDialog from "../../../components/dialogs/ErrorDialog";
import { t } from "i18next";

type TCameraOverlayProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const ScannerPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(true);

  const onClose = () => {
    setIsOpen(false);
    navigate(-1);
  };

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col   bg-white px-5 pb-11 pt-4 ">
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={onClose}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>
            </Transition.Child>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full justify-center  text-center sm:items-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-600"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative h-screen w-screen transform overflow-hidden  bg-white    text-left shadow-xl transition-all  sm:h-full">
                    <Outlet />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <ErrorDialog
          error={error}
          onClose={() => setError(null)}
          title={t("SCANNER_PAGE.PRODUCT_NOT_FOUND_TITLE")}
          description={t("SCANNER_PAGE.PRODUCT_NOT_FOUND_DESCRIPTION")}
          buttonLabel={"SCANNER_PAGE.PRODUCT_NOT_FOUND_BUTTON_LABEL"}
        />
      </div>
    </div>
  );
};
