export function hex2lab(hex: string) {
  const [r, g, b, a] = hex2rgba(hex);
  const [x, y, z] = rgb2xyz(r, g, b, a);
  return xyz2lab(x, y, z); // [l, a, b]
}

export function xyz2lab(x: number, y: number, z: number) {
  // using 10o Observer (CIE 1964)
  // CIE10_D65 = {94.811f, 100f, 107.304f} => Daylight
  const referenceX = 94.811;
  const referenceY = 100;
  const referenceZ = 107.304;
  // step 1
  x = x / referenceX;
  y = y / referenceY;
  z = z / referenceZ;
  // step 2
  if (x > 0.008856) {
    x = Math.pow(x, 1 / 3);
  } else {
    x = 7.787 * x + 16 / 116;
  }
  if (y > 0.008856) {
    y = Math.pow(y, 1 / 3);
  } else {
    y = 7.787 * y + 16 / 116;
  }
  if (z > 0.008856) {
    z = Math.pow(z, 1 / 3);
  } else {
    z = 7.787 * z + 16 / 116;
  }
  // step 3
  const l = 116 * y - 16;
  const a = 500 * (x - y);
  const b = 200 * (y - z);
  return [l, a, b];
}

export function hex2rgba(hex: string) {
  let c: any = [];
  if (hex.charAt(0) === "#") {
    c = hex.substring(1).split("");
  }
  if (c.length > 6 || c.length < 3) {
    throw new Error(
      `HEX colour must be 3 or 6 values. You provided it ${c.length}`
    );
  }
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = "0x" + c.join("");
  const r: number = (c >> 16) & 255;
  const g: number = (c >> 8) & 255;
  const b: number = c & 255;
  const a = 1;
  return [r, g, b, a];
}

export function rgb2xyz(r: number, g: number, b: number, a = 1) {
  if (r > 255) {
    // console.warn("Red value was higher than 255. It has been set to 255.");
    r = 255;
  } else if (r < 0) {
    // console.warn("Red value was smaller than 0. It has been set to 0.");
    r = 0;
  }
  if (g > 255) {
    // console.warn("Green value was higher than 255. It has been set to 255.");
    g = 255;
  } else if (g < 0) {
    // console.warn("Green value was smaller than 0. It has been set to 0.");
    g = 0;
  }
  if (b > 255) {
    // console.warn("Blue value was higher than 255. It has been set to 255.");
    b = 255;
  } else if (b < 0) {
    // console.warn("Blue value was smaller than 0. It has been set to 0.");
    b = 0;
  }
  if (a > 1) {
    // console.warn("Obacity value was higher than 1. It has been set to 1.");
    a = 1;
  } else if (a < 0) {
    // console.warn("Obacity value was smaller than 0. It has been set to 0.");
    a = 0;
  }
  r = r / 255;
  g = g / 255;
  b = b / 255;
  // step 1
  if (r > 0.04045) {
    r = Math.pow((r + 0.055) / 1.055, 2.4);
  } else {
    r = r / 12.92;
  }
  if (g > 0.04045) {
    g = Math.pow((g + 0.055) / 1.055, 2.4);
  } else {
    g = g / 12.92;
  }
  if (b > 0.04045) {
    b = Math.pow((b + 0.055) / 1.055, 2.4);
  } else {
    b = b / 12.92;
  }
  // step 2
  r = r * 100;
  g = g * 100;
  b = b * 100;
  // step 3
  const x = r * 0.4124564 + g * 0.3575761 + b * 0.1804375;
  const y = r * 0.2126729 + g * 0.7151522 + b * 0.072175;
  const z = r * 0.0193339 + g * 0.119192 + b * 0.9503041;
  return [x, y, z];
}

function rad2deg(rad: number) {
  return (360 * rad) / (2 * Math.PI);
}

function deg2rad(deg: number) {
  return (2 * Math.PI * deg) / 360;
}

export function deltaE00(
  l1: number,
  a1: number,
  b1: number,
  l2: number,
  a2: number,
  b2: number
) {
  // Start Equation
  // Equation exist on the following URL http://www.brucelindbloom.com/index.html?Eqn_DeltaE_CIE2000.html
  const avgL = (l1 + l2) / 2;
  const c1 = Math.sqrt(Math.pow(a1, 2) + Math.pow(b1, 2));
  const c2 = Math.sqrt(Math.pow(a2, 2) + Math.pow(b2, 2));
  const avgC = (c1 + c2) / 2;
  const g =
    (1 - Math.sqrt(Math.pow(avgC, 7) / (Math.pow(avgC, 7) + Math.pow(25, 7)))) /
    2;

  const a1p = a1 * (1 + g);
  const a2p = a2 * (1 + g);

  const c1p = Math.sqrt(Math.pow(a1p, 2) + Math.pow(b1, 2));
  const c2p = Math.sqrt(Math.pow(a2p, 2) + Math.pow(b2, 2));

  const avgCp = (c1p + c2p) / 2;

  let h1p = rad2deg(Math.atan2(b1, a1p));
  if (h1p < 0) {
    h1p = h1p + 360;
  }

  let h2p = rad2deg(Math.atan2(b2, a2p));
  if (h2p < 0) {
    h2p = h2p + 360;
  }

  const avghp =
    Math.abs(h1p - h2p) > 180 ? (h1p + h2p + 360) / 2 : (h1p + h2p) / 2;

  const t =
    1 -
    0.17 * Math.cos(deg2rad(avghp - 30)) +
    0.24 * Math.cos(deg2rad(2 * avghp)) +
    0.32 * Math.cos(deg2rad(3 * avghp + 6)) -
    0.2 * Math.cos(deg2rad(4 * avghp - 63));

  let deltahp = h2p - h1p;
  if (Math.abs(deltahp) > 180) {
    if (h2p <= h1p) {
      deltahp += 360;
    } else {
      deltahp -= 360;
    }
  }

  const deltalp = l2 - l1;
  const deltacp = c2p - c1p;

  deltahp = 2 * Math.sqrt(c1p * c2p) * Math.sin(deg2rad(deltahp) / 2);

  const sl =
    1 +
    (0.015 * Math.pow(avgL - 50, 2)) / Math.sqrt(20 + Math.pow(avgL - 50, 2));
  const sc = 1 + 0.045 * avgCp;
  const sh = 1 + 0.015 * avgCp * t;

  const deltaro = 30 * Math.exp(-Math.pow((avghp - 275) / 25, 2));
  const rc =
    2 * Math.sqrt(Math.pow(avgCp, 7) / (Math.pow(avgCp, 7) + Math.pow(25, 7)));
  const rt = -rc * Math.sin(2 * deg2rad(deltaro));

  const kl = 1;
  const kc = 1;
  const kh = 1;

  const deltaE = Math.sqrt(
    Math.pow(deltalp / (kl * sl), 2) +
      Math.pow(deltacp / (kc * sc), 2) +
      Math.pow(deltahp / (kh * sh), 2) +
      rt * (deltacp / (kc * sc)) * (deltahp / (kh * sh))
  );

  return deltaE;
}

export function calculateAverageRGBA(pixels: Uint8ClampedArray): number[] {
  const numPixels = pixels.length / 4;
  let sumR = 0;
  let sumG = 0;
  let sumB = 0;
  let sumA = 0;

  for (let i = 0; i < pixels.length; i += 4) {
    const r = pixels[i];
    const g = pixels[i + 1];
    const b = pixels[i + 2];
    const a = pixels[i + 3];

    sumR += r;
    sumG += g;
    sumB += b;
    sumA += a;
  }

  const avgR = Math.round(sumR / numPixels);
  const avgG = Math.round(sumG / numPixels);
  const avgB = Math.round(sumB / numPixels);
  const avgA = Math.round(sumA / numPixels);

  return [avgR, avgG, avgB, avgA];
}

export function invertColor(hex: string) {
  if (hex.indexOf("#") === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }
  // invert color components
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return "#" + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str: string, len?: number) {
  len = len || 2;
  const zeros = new Array(len).join("0");
  return (zeros + str).slice(-len);
}

export function getColorType(hex: string): string {
  const colorNameMap: { [key: string]: string } = {
    "#000000": "Black",
    "#ffffff": "White",
    "#ff0000": "Red",
    "#00ff00": "Green",
    "#0000ff": "Blue",
    // Add more color mappings here
    "#ff00ff": "Magenta",
    "#ffff00": "Yellow",
    "#ffa500": "Orange",
    "#800080": "Purple",
    "#008000": "Dark Green",
    // Add more color mappings here
    // ...
    // Add 100 color mappings here
  };

  const normalizedHex = hex.toLowerCase();
  if (normalizedHex in colorNameMap) {
    return colorNameMap[normalizedHex];
  } else {
    return "Unknown";
  }
}
