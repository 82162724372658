import { t } from "i18next";
import { LabledInput } from "./LabledInput";

type EmailInputLabledProps = {
  onClick?: () => void;
  testId?: string;
};

export const EmailInputLabled = ({
  onClick,
  testId,
}: EmailInputLabledProps) => {
  const rules = {
    required: "Required",
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: "invalid email address",
    },
  };

  return (
    <LabledInput
      testId={testId}
      type="email"
      name="email"
      labelText={t("REGISTER_PAGE.EMAIL_LABEL")}
      rules={rules}
      onClick={onClick}
    />
  );
};
