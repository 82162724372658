export const ProductDetailPageSkeletonLoading = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center pt-4">
      <div className="flex min-h-screen w-full max-w-xl flex-col px-5 pb-11 pt-4">
        <div className="h-6 w-16 animate-pulse  bg-gray-200" />
        <div className="mt-4 h-80 w-96 animate-pulse  bg-gray-200" />
        <div className="mt-14 h-20 w-full animate-pulse  bg-gray-200" />
        <div className="mt-6 h-14 w-full animate-pulse rounded-sm  bg-black" />
        <div className="mt-6 h-8 w-28 animate-pulse rounded-sm  bg-gray-200" />
        <div className="flex justify-between">
          <div>
            <div className="mt-2 h-6 w-60 animate-pulse rounded-sm  bg-gray-200" />
            <div className="mt-2 h-6 w-60 animate-pulse rounded-sm  bg-gray-200" />
            <div className="mt-4 h-6 w-60 animate-pulse rounded-sm  bg-gray-200" />
          </div>
          <div className="mt-2 mr-4 h-24 w-24 animate-pulse rounded-full  bg-gray-200"></div>
        </div>
      </div>
    </div>
  );
};
