import { TProduct } from "../types";
import { getRequest } from "./getRequest";

export const getAllProductsRequest = async (): Promise<TProduct[]> => {
  try {
    const searchUrl = `${import.meta.env.VITE_BACKEND_URL}/findAllProducts`;

    const allProducts = await getRequest(searchUrl);

    if (!allProducts || Object.keys(allProducts).length === 0) {
      return [];
    }

    return allProducts;
  } catch (error) {
    console.log(error); // TODO show this to the user
    return [];
  }
};
