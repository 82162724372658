import { t } from "i18next";
import { extractUnit } from "../../../../components/carousell/DupeCarousell/utils";
import { TProduct } from "../../../../types";
import { formatPrice } from "../../../utils/formatPrice";
import { formatSize } from "../../../utils/formatSize";
import { isPriceInRange } from "../../../utils/priceInRange";
import { PricePer100ml } from "../../../components/text/Price";

type TPriceSectionMatchCardProps = {
  product: TProduct;
};

export const PriceSectionMatchCard = ({
  product,
}: TPriceSectionMatchCardProps) => {
  const priceInRange = isPriceInRange(Number(product?.price_de));

  const price = priceInRange ? (
    <>
      {t("SHARED.FROM_LABEL")}
      <span>&sup1;</span> {formatPrice(product?.price_de)}
      <span>&#8202;</span>€
      <span className="text-xs text-gray-300"> {t("SHARED.VAT_LABEL")} </span>
    </>
  ) : (
    `${t("SHARED.PRICE_NA_LONG")} `
  );

  return (
    <div className="flex w-full items-baseline justify-between  ">
      <div className="flex w-2/3 flex-col items-baseline ">
        <div>
          <span className="font-circular-book text-base ">{price}</span>
        </div>
        <div>
          <span className="ml-1 font-circular-book text-xs text-gray-400">
            {formatSize(product.sizeml)} |{" "}
            {priceInRange ? (
              <PricePer100ml price={product.price_de} sizeml={product.sizeml} />
            ) : (
              t("SHARED.PRICE_NA")
            )}{" "}
            / 100 {extractUnit(product.sizeml)}
          </span>
        </div>
      </div>
      <div className="/3 flex  w-1 justify-end font-circular-medium text-base underline ">
        <span className="decoration-2">{t("SHARED.MORE_LABEL")}</span>
      </div>
    </div>
  );
};
