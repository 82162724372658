import { Link } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const footerLinks = [
  {
    id: "1",
    label: "LINK_DATAPOLICY",
    link: "/de/datenschutz",
  },
  {
    id: "2",
    label: "LINK_IMPRINT",
    link: "/de/impressum",
  },
  {
    id: "3",
    label: "LINK_TERMS",
    link: "/de/nutzungsbedingungen",
  },
  {
    id: "4",
    label: "LINK_CONTACT",
    link: "/de/kontakt",
  },
];
const remarks = [
  "FOOTER.REMARK_HOW_IT_WORKS",
  "FOOTER.REMARK_PRICE_CALCULATION",
  "FOOTER.REMARK_INCL_VAT",
];

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div
      className="flex w-full  flex-wrap items-center justify-start bg-black px-4 pb-10 md:justify-center"
      data-cy="footer"
    >
      <div className="mt-10 flex max-w-sm flex-col">
        {remarks?.map((remark, index) => (
          <span
            key={index}
            className=" mt-4 px-2 text-left font-circular-book  text-xs leading-5 text-white"
          >
            <Trans
              i18nKey={remark}
              components={{
                link1: (
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/dupefinder-faa09.appspot.com/o/MATCHED_Matchscore_20240814%20(1).pdf?alt=media&token=0fc7b98b-288a-4468-a757-db07f8b7d530"
                    className="font-circular-book underline"
                  >
                    hier
                  </a>
                ),
                link2: (
                  <a
                    href="/de/faq?jumpTo=pricecalculation"
                    className="font-circular-book underline"
                  >
                    hier
                  </a>
                ),
                hoch1: <span>&sup1;</span>,
                hoch2: <span>&sup2;</span>,
              }}
            />
          </span>
        ))}
      </div>
      <div className="mt-10 grid grid-cols-2 gap-2">
        {footerLinks.map((element) => (
          <Link
            to={element.link}
            key={element.id}
            data-cy={`footer-item-${element.id}`}
          >
            <span className="px-2 font-circular-book text-base leading-5 text-white underline">
              {t(`FOOTER.${element.label}`)}
            </span>
          </Link>
        ))}
      </div>
    </div>
  );
};
