import { calculateAverageRGBA } from "../../../../utils/colorCalculations";

export function pickColor(x: number, y: number, ctx: CanvasRenderingContext2D) {
  const pixel = ctx.getImageData(x, y, 3, 3);

  const data = pixel.data;

  const averageColor = calculateAverageRGBA(data);

  const red = averageColor[0];
  const green = averageColor[1];
  const blue = averageColor[2];

  let hex = rgbToHex(red, green, blue);

  if (hex === "0") {
    hex = "000000";
  }

  const event = new CustomEvent("colorPicked", { detail: hex });
  window.dispatchEvent(event);
}

function rgbToHex(r: number, g: number, b: number) {
  if (r > 255 || g > 255 || b > 255) throw "Invalid color component";
  return ((r << 16) | (g << 8) | b).toString(16);
}
