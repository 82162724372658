export const postRequest = async (url: string, data: any) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok || response.status !== 200) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  const responseData = await response.json();
  return responseData;
};
