import { classNames } from "../../../../../shared";
import { MatchicButton } from "../../../buttons/MatchicButton";

type TScanButtonProps = {
  onRequestScan: () => void;
};

export const ScanButton = ({ onRequestScan }: TScanButtonProps) => {
  return (
    <div className="relative overflow-hidden rounded-full">
      <button
        onClick={onRequestScan}
        className={classNames(
          "flex h-12 w-12 items-center justify-center rounded-full"
        )}
      >
        <div className="absolute">
          <MatchicButton />
        </div>
      </button>
    </div>
  );
};
