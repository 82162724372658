import "../../../extra.scss";

export const MatchicButton = () => {
  return (
    <>
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-black">
        <img
          src="/v2/icons/matched-icon.png"
          className="h-6 w-6 invert"
          alt="logo"
        ></img>

        {/* <BarcodeIcon
          strokeColor="#ffffff"
          className="h-6 w-6 text-white"
          style={{ color: "#ffffff" }}
        /> */}
      </div>
    </>
  );
};
