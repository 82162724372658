import React, { Suspense } from "react";
import { SearchBar } from "./components/SearchBar";
import FilterBar from "./components/FilterBar/FilterBar";
import { SearchResultList } from "./components/SearchResultList";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { SearchResultCardSkeleton } from "./components/SearchResultCardSkeleton";

export const SearchPage: React.FC = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col   overflow-y-hidden px-5 pb-11 pt-4">
        <div className=" mb-6  w-full ">
          <Suspense
            fallback={
              <div className="h-14 w-full animate-pulse rounded bg-gray-200"></div>
            }
          >
            <SearchBar />
          </Suspense>
        </div>
        <div className="h-16 w-full ">
          <FilterBar />
        </div>
        <div className=" w-full">
          <Suspense fallback={<SearchResultCardSkeleton />}>
            <SearchResultList />
          </Suspense>
        </div>
        <BottomNavBar />
      </div>
    </div>
  );
};
