import { data } from "cypress/types/jquery";
import { TColorMatch } from "../../state/atoms/colorMatchMemoryAtom";
import { TDupeProduct, TProduct } from "../../types";
import { track } from "./track";

export const trackSeachSuggestionClicked = (option: string) =>
  track("link", {
    tealium_event: "search_suggestion_click",
    page_context: "n/a",
    sitesearch_suggest: [option],
  });

// export const trackSiteSearchWithBarcodeComplete = (
//   barcodeGtin: string,
//   searchResults: number
// ) =>
//   // After search for twin products
//   track("link", {
//     tealium_event: "sitesearch_complete",
//     page_context: "n/a",
//     search_category: "Scan",
//     search_method: "Product Search",
//     // "search_option": "type search", Suggest Freeform
//     //TODO barcode of the item
//     sitesearch_keyword: barcodeGtin,
//     search_results: searchResults,
//   });

// export const trackPurchaseSubscription = () => {
//   // TODO: This was done because the tealium event mapping is not working
//   // and we needed to track these manually. Hopefully the tealium tracking will be fixed soon
//   if (window?.ttq?.track) {
//     window.ttq.track("Subscribe", {
//       currency: "EUR",
//       value: "2.99",
//       content_id: "123",
//     });
//   }

//   if (window?.fbq) {
//     window.fbq("track", "Purchase", {
//       currency: "EUR",
//       value: "2.99",
//     });
//   }

//   track("link", {
//     tealium_event: "purchase",
//     currency_code: "EUR",
//     order_value: "2.99",
//     order_total_gross: "2.99",
//   });
// };

// TODO wait for Max response here: DUP-232
// On click on show me the shops ====> implemented!
export const trackStoreButtonClick = (product: TProduct) => {
  track("link", {
    tealium_event: "store_button_click",
    page_context: "n/a",
    product_gtin: [product?.gtin?.toString()],
    product_id: [product?.fid?.toString()],
    product_name: [product?.namecomplete],
    product_price_gross: [product?.price_de?.toString()],
    product_brand: [product?.brand],
    product_variant: [product?.colormain],
    product_position: ["n/a"],
    currency_code: "EUR",
    product_price_net: ["n/a"],
    product_tax: ["n/a"],
  });
};

export const trackDupeListSwitch = (product: TProduct, toggleType: string) =>
  track("link", {
    tealium_event: "dupe_list_switch",
    data_source: "FEdataLayer",
    page_context: "n/a",
    product_impression_list:
      toggleType === "price" ? "dupe_list_bestprice" : "dupe_list_bestmatch",
    product_gtin: [product.gtin?.toString()],
    product_id: [product.fid?.toString()],
    product_name: [product?.namecomplete],
    product_price_gross: [product?.price_de?.toString()],
    product_brand: [product?.brand?.toString()],
    product_variant: ["n/a"],
    product_position: [1],
    currency_code: "EUR",
    product_price_net: ["n/a"],
    product_tax: ["n/a"],
  });

// On click on look for product online ====> implemented!
// export const trackSearchProductButtonClick = (
//   product: TProduct | null | undefined,
//   toggleType: string
// ) => {
//   if (!product) return;
//   track("link", {
//     tealium_event: "store_button_search",
//     page_context: "n/a",
//     product_impression_list:
//       toggleType === "price" ? "dupe_list_bestprice" : "dupe_list_bestmatch",
//     product_gtin: [product?.gtin?.toString()],
//     product_id: [product?.fid?.toString()],
//     product_name: [product?.namecomplete],
//     product_price_gross: [product?.price_de?.toString()],
//     product_brand: [product?.brand],
//     product_variant: [product?.colormain],
//     product_position: ["n/a"],
//     currency_code: "EUR",
//     product_price_net: ["n/a"],
//     product_tax: ["n/a"],
//   });
// };

// On Product Clicked in dupe view ====> implemented!

// On Dupe Menu open ====> implemented!
export const trackMenuOpen = () =>
  track("link", {
    tealium_event: "menu_open",
    page_context: "n/a",
  });

export const trackProductImpression = (
  products: TProduct[] = [],
  pageContext: string,
  productImpressionList: string
) =>
  track("link", {
    tealium_event: "product_impression",
    data_source: "FEdataLayer",
    page_context: pageContext,
    product_impression_list: productImpressionList,
    product_impression_gtin: products?.map((product) => product.gtin),
    product_impression_id: products?.map((product) => product.fid),
    product_impression_name: products?.map((product) => product.namecomplete),
    product_impression_price_gross: products?.map(
      (product) => product.price_de
    ),
    product_impression_brand: products?.map((product) => product.brand),
    product_impression_variant: products?.map((product) => product.colormain),
    product_impression_position: products?.map((product) => product.gtin),
    currency_code: "EUR",
    product_impression_price_net: ["n/a"],
    product_tax: ["n/a"],
  });

export const trackAccountLogin = (userId: string) =>
  track("link", {
    tealium_event: "account_login",
    data_source: "FEdataLayer",
    page_context: "Login",
    customer_truepid: userId,
  });

export const trackAccountCreateStart = () => {
  track("link", {
    tealium_event: "account_create_start",
    data_source: "FEdataLayer",
    page_context: "Registration",
  });
};

export const trackAccountCreateComplete = (userId: string) =>
  track("link", {
    tealium_event: "account_create_complete",
    data_source: "FEdataLayer",
    page_context: "Registration",
    customer_truepid: userId,
  });

export const trackFindRetailerButtonClicked = (
  product: TProduct | undefined
) => {
  if (!product) return;

  track("link", {
    tealium_event: "find_retailer_button_clicked",
    data_source: "FEdataLayer",
    page_context: "match-details",
    cart_total_gross: "NA",
    product_gtin: [product.gtin],
    product_id: [product.fid],
    product_name: [product.namecomplete],
    product_price_gross: [product.price_de],
    product_brand: [product.brand],
    product_color: [product.colormain],
    product_variant: [],
    product_quantity: [],
    currency_code: "EUR",
    product_price_net: [],
    product_tax: [],
    cart_total_net: "NA",
  });
};

export const trackInstoreMatch = (product: TProduct) => {
  track("link", {
    tealium_event: "instore_match",
    data_source: "FEdataLayer",
    page_context: "ProductFinder",
    search_category: "Filters",
    search_method: "instore_match",
    search_results: [],
    product_gtin: product?.gtin,
    product_id: product?.id,
    product_name: product?.namecomplete,
    product_price_gross: product?.price_de,
    product_brand: product?.brand,
    product_color: product?.colormain,
  });
};

export const trackMatchCosmosOpenend = (product: TProduct) => {
  track("link", {
    tealium_event: "matched_cosmos_opened",
    data_source: "FEdataLayer",
    page_context: "match_details",
    product_gtin: [product.gtin],
    product_id: [product.fid],
    product_name: [product.namecomplete],
  });
};

export const trackNewsletterComplete = (userId: string) => {
  track("link", {
    tealium_event: "newsletter_complete",
    data_source: "FEdataLayer",
    page_context: "Registration",
    customer_truepid: userId,
  });
};

export const teTrackProductClick = (
  product: TProduct,
  productPosition: number,
  pageContext: string
) =>
  track("link", {
    tealium_event: "product_click",
    data_source: "FEdataLayer",
    page_context: pageContext,
    product_impression_list: "navigation",
    product_gtin: [product?.gtin],
    product_id: [product?.fid],
    product_name: [product?.namecomplete],
    product_price_gross: [product?.price_de],
    product_brand: [product?.brand],
    product_variant: ["n/a"],
    product_position: [productPosition],
    product_color: [product?.colormain],
    currency_code: "EUR",
    product_price_net: ["n/a"],
    product_tax: ["n/a"],
    product_tag: ["n/a"],
  });

export const trackProductFilterComplete = (
  numberOfSearchResults: string,
  filters: string[]
) =>
  track("link", {
    tealium_event: "productfilter_complete",
    data_source: "FEdataLayer",
    page_context: "Search_results",
    search_category: "Filters",
    search_method: "Suggest",
    search_option: "Product Filter",
    search_results: numberOfSearchResults,
    search_filter_product: filters, //["recommendedFilter:lipstick"],
  });

export const trackProductView = (dupeProduct: TDupeProduct) => {
  track("link", {
    tealium_event: "product_view",
    data_source: "FEdataLayer",
    page_context: "ProductDetails",
    product_gtin: [dupeProduct?.gtin_twinproduct?.toString()],
    product_id: [dupeProduct?.data.fid?.toString()],
    product_name: [dupeProduct?.data?.namecomplete],
    product_price_gross: [dupeProduct?.data?.price_de?.toString()],
    product_brand: [dupeProduct?.data.brand],
    product_variant: ["n/a"],
    product_color: [dupeProduct?.data.colormain],
    currency_code: "EUR",
    product_price_net: ["n/a"],
    product_tax: ["n/a"],
    product_tag: ["n/a"],
  });
};

export const trackShopNowButtonClick = (product: TProduct) => {
  track("link", {
    tealium_event: "shop_now_button_clicked",
    data_source: "FEdataLayer",
    page_context: "Match-details",
    product_gtin: [product.gtin],
    product_id: [product.fid],
    product_name: [product.namecomplete],
    product_brand: [product.brand],
    product_variant: ["n/a"],
    product_color: [product.colormain],
    product_position: ["n/a"],
  });
};

export const trackSiteSearchComplete = (
  numberOfSearchResults: string,
  query: string
) => {
  track("link", {
    tealium_event: "sitesearch_complete",
    data_source: "FEdatLayer",
    page_context: "Searchbar",
    search_category: "Search Bar",
    search_method: "Sitesearch",
    search_option: "Freeform",
    sitesearch_keyword: query,
    search_results: numberOfSearchResults,
  });
};

export const trackColorMatchCompleteEvent = (colorMatch: TColorMatch) => {
  track("link", {
    tealium_event: "color_match_complete",
    data_source: "FEdataLayer",
    page_context: "ColorMatch",
    search_category: "Filters",
    search_step: "3",
    search_method: "color_match",
    search_results: "10",
    product_gtin: colorMatch.matches.map((p: TProduct) => p.gtin),
    product_id: colorMatch.matches.map((p: TProduct) => p.fid),
    product_name: colorMatch.matches.map((p: TProduct) => p.namecomplete),
    product_price_gross: colorMatch.matches.map((p: TProduct) => p.price_de),
    product_brand: colorMatch.matches.map((p: TProduct) => p.brand),
    product_color: colorMatch.matches.map((p: TProduct) => p.colormain),
    searched_color: colorMatch.hex!,
  });
};

export const trackColorMatchProgress = () => {
  track("link", {
    tealium_event: "color_match_progress",
    data_source: "FEdataLayer",
    page_context: "ColorMatch",
    search_category: "Filters",
    search_step: "2",
    search_method: "colour_match",
  });
};

export const trackColorMatchStart = () => {
  track("link", {
    tealium_event: "color_match_start",
    data_source: "FEdataLayer",
    page_context: "ColorMatch",
    search_category: "Filters",
    search_step: "1",
    search_method: "color_match",
  });
};

export const trackContentClicked = (
  contentName: string,
  contentTitle: string,
  position: number
) => {
  track("link", {
    tealium_event: "content_clicked",
    data_source: "FEdataLayer",
    page_context: "Home",
    content_name: [contentName],
    content_slider_position: [String(position)],
    content_title: [contentTitle],
  });
};

// TODO: DUP-232 remove if confirmed by max
// export const trackAccountLogout = () =>
//   track("link", {
//     tealium_event: "account_logout",
//     page_context: "n/a",
//   });

// USED FOR THE LANDING PAGE
// track("link", {
//   tealium_event: "find_match_clicked",
//   page_context: "n/a",
// });

// track("link", {
//   tealium_event: "search_suggestion_impression",
//   page_context: "n/a",
//   sitesearch_suggest: ["Nails", "Lips"],
//   content_slider_position: [1, 2],
// });
