type TChooseAScannerButtonsProps = {
  onChoosenColorMatch: () => void;
  onChoosenBarcodeScanner: () => void;
};

export const ChooseAScannerButtons = ({
  onChoosenColorMatch,
  onChoosenBarcodeScanner,
}: TChooseAScannerButtonsProps) => {
  return (
    <div className="absolute top-1/2 left-1/2 z-10 flex w-72 max-w-xl -translate-x-1/2 -translate-y-1/2 justify-between ">
      <div className="flex flex-col items-center justify-center">
        <button
          onClick={onChoosenColorMatch}
          className="flex h-20 w-20 items-center justify-center rounded-full   bg-white bg-opacity-90"
        >
          <img
            className={`h-10 w-full object-contain`}
            src="/v2/icons/color-match-icon.png"
            alt="logo"
          />
        </button>
        <span className="mt-2 font-circular-book text-base text-white">
          Color Match
        </span>
      </div>
      <div className="flex flex-col items-center justify-center">
        <button
          onClick={onChoosenBarcodeScanner}
          className="flex h-20 w-20 items-center justify-center rounded-full   bg-white bg-opacity-90"
        >
          <img
            className={`h-10 w-full object-contain`}
            src="/v2/icons/instore-match-icon.png"
            alt="logo"
          />
        </button>
        <span className="mt-2 font-circular-book text-base text-white">
          In Store Match
        </span>
      </div>
    </div>
  );
};
