import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { BottomModal } from "../../components/modals/BottomModal";
import { FormProvider, useForm } from "react-hook-form";
import { EmailInputLabled } from "../../components/inputs/EmailInputLabled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/buttons/Button";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useTranslation } from "react-i18next";

type Inputs = {
  email: string;
};

export const ForgotPasswordModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [loading, setLoading] = useState(false);
  const methods = useForm<Inputs>();
  const naivgate = useNavigate();
  const { t } = useTranslation();

  const onSubmit = async ({ email }: Inputs) => {
    try {
      setLoading(true);
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      naivgate("/de/login?modal=reset_send");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      testId="forgot-password-bottom-modal"
    >
      <Dialog.Panel className="w-full rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
        <div className="absolute right-0 top-8  pr-4 pt-4 sm:block">
          <button
            data-cy="forgot-password-bottom-modal-close-button"
            type="button"
            className="rounded-md   text-white hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mt-8 mb-5 flex w-full items-center justify-center ">
              <h2 className="font-circular-medium text-2xl font-medium text-white">
                {t("FORGOT_PASSSWORD_PAGE.TITLE")}
              </h2>
            </div>
            <div className="flex w-full items-center justify-center">
              <span className="mb-6 font-circular-book text-base text-white">
                {t("FORGOT_PASSSWORD_PAGE.DESCRIPTION")}
              </span>
            </div>
            <div className="mb-5">
              <EmailInputLabled testId="forgot-password-email-input" />
            </div>

            <div className="mb-5">
              <Button
                testId="forgot-password-submit-button"
                type="submit"
                text={t("FORGOT_PASSSWORD_PAGE.BUTTON_LABEL")}
                styleType="secondary"
                loading={loading}
              />
            </div>
          </form>
        </FormProvider>
      </Dialog.Panel>
    </BottomModal>
  );
};
