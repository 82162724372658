import { TShopData } from "../../../../types";

export function filterShownShops(shops_data: TShopData[]) {
  return useAwinLinks(
    shops_data.map((shop: TShopData) => ({
      ...shop,
      retailer: normalizeRetailerName(shop.retailer),
    }))
  );
}

function useAwinLinks(shops: TShopData[]): TShopData[] {
  return shops.reduce((acc: TShopData[], shop: TShopData) => {
    const shopDuplicateIndex = acc.findIndex(
      (accShop) => accShop.retailer === shop.retailer
    );

    if (shopDuplicateIndex === -1) {
      acc.push(shop);
      return acc;
    }

    const shopDuplicate = acc[shopDuplicateIndex];

    if (shopDuplicate && shopDuplicate.url.includes("awin1.com")) {
      return acc;
    } else {
      acc[shopDuplicateIndex] = shop;
    }

    return acc;
  }, []);
}

function normalizeRetailerName(retailer: string) {
  const underscoreIndex = retailer.indexOf("_");
  if (underscoreIndex !== -1) {
    retailer = retailer.substring(0, underscoreIndex);
  }

  const spaceIndex = retailer.indexOf(" ");
  if (spaceIndex !== -1) {
    retailer = retailer.substring(0, spaceIndex);
  }

  let newName = retailer.charAt(0).toUpperCase() + retailer.slice(1);

  if (newName === "Dm") {
    newName = "dm";
  }

  return newName;
}
