import { useEffect, useRef } from "react";
import { trackSiteSearchComplete } from "../Tracking";

export const useTrackSearch = (searchStringFilter: string) => {
  const debouce = useRef<any>(null);
  useEffect(() => {
    if (debouce.current) clearTimeout(debouce.current);

    if (searchStringFilter.length > 1) {
      debouce.current = setTimeout(() => {
        trackSiteSearchComplete("10", searchStringFilter);
      }, 1000);
    }

    return () => {
      if (debouce.current) clearTimeout(debouce.current);
    };
  }, [searchStringFilter]);
};
