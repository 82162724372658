import { EErrorType, TError, TFirebaseError } from "../../../types";
import { convertFirebaseErrorMessage } from "./convertFirebaseErrorMessage";

export const defaultErrorMessage =
  "Da ist etwas schief gelaufen. Bitte versuche es später erneut."; // TODO: add i18n

export const errorWasNullMessage = "Error was null or undefined";

export const convertError = (error: unknown): TError => {
  if (error === null || error === undefined) {
    return {
      userMessage: defaultErrorMessage,
      message: errorWasNullMessage,
      type: EErrorType.Exception,
    };
  }

  if (error === "VERIFY_EMAIL") {
    return {
      userMessage: "Bitte bestätige deine E-Mail-Adresse, um dich einzuloggen.",
      message: "VERIFY_EMAIL",
      type: EErrorType.Exception,
    };
  }

  if (isTError(error)) {
    return error;
  }

  if (isTFirebaseError(error)) {
    return {
      userMessage: convertFirebaseErrorMessage(error),
      message: JSON.stringify(error),
      type: EErrorType.FirebaseError,
    };
  }

  return {
    userMessage: defaultErrorMessage,
    message: JSON.stringify(error),
    type: EErrorType.Exception,
  };
};

function isTError(error: unknown): error is TError {
  // Add your custom validation logic here
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    "userMessage" in error &&
    "type" in error &&
    isErrorType((error as TError).type)
  );
}

function isTFirebaseError(error: unknown): error is TFirebaseError {
  // Add your custom validation logic here
  return (
    typeof error === "object" &&
    error !== null &&
    "code" in error &&
    "customData" in error &&
    "name" in error
  );
}

function isErrorType(type: unknown): type is EErrorType {
  return Object.values(EErrorType).includes(type as EErrorType);
}
