import {
  UserCredential,
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "./auth";

export const signUpNewUser = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  const user = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(user.user);
  return user;
};
