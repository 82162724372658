import { atom } from "recoil";
import { ELoadedLevel, TLoggedInUser } from "../../types";
import { checkIfUserIsSubscribed } from "../../api/checkIfUserIsSubscribedRequest";

const setUserSubscriptionStatus = (
  setSelf: (arg0: any) => void,
  newUserState: TLoggedInUser | null
) =>
  checkIfUserIsSubscribed()
    .then((hasSubscription) => {
      setSelf({
        ...newUserState,
        hasSubscription,
      });
    })
    .catch((error) => console.log(error));

export const userAtom = atom<TLoggedInUser | null>({
  key: "userAtom",
  default: {
    status: ELoadedLevel.LOADING,
    user: null,
    hasSubscription: false,
  },
  effects: [
    ({ onSet, setSelf }) => {
      onSet((newUserState, oldUserState) => {
        const oldUser = oldUserState as TLoggedInUser; // TODO: dirty fix it
        if (
          (!oldUserState && newUserState?.user?.uid) ||
          newUserState?.user?.uid !== oldUser?.user?.uid
        ) {
          setUserSubscriptionStatus(setSelf, newUserState);
        } else {
          setSelf(newUserState);
        }
      });
    },
  ],
});
