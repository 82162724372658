import { useEffect, useRef, useState } from "react";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/buttons/Button";
import { useTranslation } from "react-i18next";
import { classNames } from "../../../../shared";
import { ChooseAScannerButtons } from "./components/ChooseAScannerButtons";
import { pickColor } from "./utils/pickColor";
import { getBackCamera } from "./utils/getBackCamera";
import { getIndicatorPosition } from "./utils/getIndicatorPosition";
import { ERoutePaths } from "../../../../types";
import CrossAir from "../../../../assets/crossair.svg";
import UploadButton from "../../../../assets/uploadButton.svg";
import {
  trackColorMatchProgress,
  trackColorMatchStart,
} from "../../../../Tracking";

type TMode = "color" | "barcode" | "choose";

export const ColorScannerMatchPage = () => {
  const videoRef = useRef<any>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentColor = useRef<string | null>(null);
  // const [searchParams, setSearchParams] = useSearchParams();
  const colorPickerIndicatorRef = useRef<HTMLDivElement>(null);
  const [mode, setMode] = useState<TMode | null>(null);
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const [refAvailable, setRefAvailable] = useState(false);

  // const mode: TMode | null = searchParams.get("mode") as TMode;

  if (!mode) {
    setMode("choose");
    // setSearchParams({ mode: "choose" });
  }

  useEffect(() => {
    window.addEventListener("colorPicked", (data: any) => {
      currentColor.current = data.detail;
    });
  }, []);

  useEffect(() => {
    async function startCamera() {
      try {
        const cameraId = await getBackCamera();

        const videoConstraints = cameraId
          ? { deviceId: cameraId }
          : { facingMode: "environment" };

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: videoConstraints,
        });

        setCameraStream(stream);
      } catch (error) {
        console.log(error);
        alert("Es gab ein problem beim starten der Kammera");
      }
    }
    startCamera();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!videoRef.current || !colorPickerIndicatorRef.current || !canvas) {
      return console.log("videoRef.current is null");
    }

    const context = canvas.getContext("2d");

    if (!context) return console.error("context is null");

    const triggerColorPicker = () => {
      try {
        if (!videoRef.current) return;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

        pickColor(canvas.width / 2, canvas.height / 3, context);
      } catch (error) {
        console.log(error);
      }
    };

    const intervalId = setInterval(triggerColorPicker, 10);

    return () => {
      clearInterval(intervalId);
    };
  }, [mode]);

  useEffect(() => {
    if (
      !colorPickerIndicatorRef.current ||
      !videoContainerRef.current ||
      !refAvailable
    )
      return console.log(
        colorPickerIndicatorRef.current,
        videoRef.current,
        cameraStream
      );

    const { top, left } = getIndicatorPosition(
      videoContainerRef.current,
      colorPickerIndicatorRef.current
    );
    colorPickerIndicatorRef.current.style.top = top;
    colorPickerIndicatorRef.current.style.left = left;
  }, [mode, refAvailable]);

  const shutdownCamera = () => {
    cameraStream?.getTracks().forEach((track) => track.stop());
    setCameraStream(null);

    if (!videoRef.current) return;
    videoRef.current.srcObject = null;
  };

  const handleImageUpload = (event: any) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = async () => {
      const imageDataUrl = reader.result as string;
      // Save the imageDataUrl to state or do something with it
      // Navigate to another page
      await shutdownCamera();
      navigate(ERoutePaths.COLORMATCH_UPLOAD, {
        state: { imageDataUrl },
      });
    };
    reader.readAsDataURL(file);
  };

  const onPickColor = async () => {
    await shutdownCamera();
    trackColorMatchProgress();
    setTimeout(() => {
      navigate(`/de/scanner/pick-categroy/${currentColor.current}`);
    }, 500);
  };

  const onBackClicked = async () => {
    if (mode === "color") {
      setMode("choose");
      // setSearchParams({ mode: "choose" });
    } else {
      // Give the camera time to acctually shutdown, otherwise problems in  mobile safari
      await shutdownCamera();
      navigate("/");
    }
  };

  const onClose = async () => {
    await shutdownCamera();
    navigate("/");
  };

  const onNaviagteToBarcodeScanner = async () => {
    await shutdownCamera();
    navigate("/de/scanner/instore-match");
  };

  return (
    <div className={"flex w-full items-center justify-center overscroll-none"}>
      <div
        className="relative min-h-screen w-full max-w-xl object-contain sm:h-screen sm:w-screen"
        ref={videoContainerRef}
      >
        <div className="absolute left-1/2 top-2 z-10 -translate-x-1/2 font-circular-book text-xl text-white">
          <img
            data-cy="nav-bar-home-button"
            className="h-16 w-24 object-contain"
            src="/matched_logo.png"
            alt="logo"
          />
        </div>
        <button
          type="button"
          className="absolute top-4  left-4 z-10 rounded-full p-4 focus:outline-none"
          onClick={onBackClicked}
        >
          <span className="sr-only">Close panel</span>
          <ChevronLeftIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
        <button
          type="button"
          className="absolute top-4  right-4 z-10 rounded-full p-4 focus:outline-none"
          onClick={onClose}
        >
          <span className="sr-only">Close panel</span>
          <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </button>
        {mode === "choose" ? (
          <ChooseAScannerButtons
            onChoosenColorMatch={() => {
              trackColorMatchStart();
              setMode("color");
            }}
            onChoosenBarcodeScanner={onNaviagteToBarcodeScanner}
          />
        ) : null}

        <video
          muted
          autoPlay
          playsInline
          ref={(videoEl) => {
            videoEl && (videoEl.srcObject = cameraStream);
            videoRef.current = videoEl;
            setRefAvailable(true);
          }}
          className={classNames(
            " absolute left-0  top-0 h-screen  w-full   object-cover opacity-100 "
          )}
          id="vid"
        />

        <canvas ref={canvasRef} style={{ display: "none" }} />
        {mode === "color" ? (
          <>
            <div
              ref={colorPickerIndicatorRef}
              className="rounded-full"
              style={{
                position: "absolute",
              }}
            >
              <img
                data-cy="nav-bar-home-button"
                className="h-10 w-10 object-contain"
                src={CrossAir}
                alt="logo"
              />
            </div>

            <div className="absolute left-5 bottom-48 active:translate-y-1 active:scale-90">
              <label htmlFor="upload-button">
                <img src={UploadButton} alt="scan" />
              </label>
              <input
                type="file"
                id="upload-button"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
            </div>

            <ColorPreview onPressed={onPickColor} />

            <div className="absolute bottom-24 z-10 w-full bg-opacity-0 px-5">
              <Button
                styleType="secondary"
                text={t("SCANNER_PAGE.SELECT_COLOR_BUTTON_LABEL")}
                onClick={onPickColor}
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const ColorPreview = ({ onPressed }: { onPressed: () => void }) => {
  const [pickedColor, setPickedColor] = useState("");

  useEffect(() => {
    window.addEventListener("colorPicked", (data: any) => {
      setPickedColor(`#${data.detail}`);
    });
  }, []);

  return (
    <button
      onClick={onPressed}
      className="absolute bottom-36 left-1/2 z-20 h-20 w-20 -translate-x-1/2 -translate-y-1/2 transform rounded-full  outline  outline-offset-4 outline-white"
      style={{ backgroundColor: pickedColor }}
    ></button>
  );
};
