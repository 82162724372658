import { LoadingIndicator, Logo } from "../components";

// TODO: Replace with acctual loading indicator
export const LoadingPage = () => {
  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex min-h-screen w-full max-w-xl flex-col   px-5 pb-11 pt-4">
        <div className="flex w-full justify-center py-10">
          <img
            className={`h-10 w-full object-contain`}
            src="/matched_logo_black.png"
            alt="logo"
          />
        </div>
        <div className="w-wull flex h-full justify-center py-40">
          <LoadingIndicator />
        </div>
      </div>
    </div>
  );
};
