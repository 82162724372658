import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { selectedDupeGtinAtom } from "../../../state/atoms/selectedDupeGtinAtom";
import { Suspense, useEffect } from "react";
import { ProductToMatchCard } from "./components/ProductToMatchCard";
import { PriceMatchToggle } from "./components/PriceMatchToggle";
import { classNames } from "../../../shared";
import { MatchSlider } from "./components/MatchSlider";
import { currentlyVisibleMatchAtom } from "../../../state/atoms/currentlyVisibleMatchAtom";
import { MatchSliderLoadingSkeleton } from "./components/MatchSlideLoadingSkeleton";
import { ERoutePaths } from "../../../types";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { calculateColorBrightness } from "./utils/calculateColorBrightness";
import { t } from "i18next";

export const MatchPage = () => {
  const navigate = useNavigate();
  const [selectedDupeGtin, setSelectedDupeGtin] =
    useRecoilState(selectedDupeGtinAtom);
  const { gtin } = useParams();

  const currentBgColor = useRecoilValue(currentlyVisibleMatchAtom);

  const onGoBack = () => {
    navigate(ERoutePaths.SEARCH);
  };

  useEffect(() => {
    if (gtin && selectedDupeGtin !== gtin) {
      setSelectedDupeGtin(gtin);
    }
  }, [selectedDupeGtin, setSelectedDupeGtin, gtin]);

  const brightnessValue = calculateColorBrightness(currentBgColor || "");

  // make sure the circle is visbile if the match color is very bright
  const fontColor = brightnessValue > 200 ? "black" : "white";

  return (
    <div
      className={classNames(
        "flex w-full items-center justify-center bg-gray-200 transition-colors duration-500 ease-in-out"
      )}
      style={{
        backgroundColor: currentBgColor || "black",
      }}
    >
      <div className="flex min-h-screen w-full max-w-xl flex-col  pb-11 pt-4">
        <div className="w-full px-5">
          <div className="w-full">
            <button
              onClick={onGoBack}
              className="font-circular-medium"
              style={{ color: fontColor || "black" }}
            >
              {t("SHARED.BACK_BUTTON_LABEL")}
            </button>
          </div>
          <div className="mt-6">
            <Suspense
              fallback={
                <div className=" w-full">
                  <div className="h-28 w-[350px] animate-pulse rounded-lg bg-gray-200"></div>
                </div>
              }
            >
              <ProductToMatchCard />
            </Suspense>
          </div>

          <div className="mt-7">
            <PriceMatchToggle />
          </div>
        </div>
        <div className="mt-7">
          {" "}
          <Suspense fallback={<MatchSliderLoadingSkeleton />}>
            <MatchSlider />
          </Suspense>
        </div>
      </div>
      <BottomNavBar />
    </div>
  );
};
