// import { aboutUsDe, aboutUsEn } from "./aboutUs";
// import { contact, contactEn } from "./contact";
// import { dataPolicy, dataPolicyEn } from "./dataPolicy";
// import { imprint, imprintEn } from "./imprint";
// import { terms, termsEn } from "./terms";

import aboutUsDe from "./aboutUs/aboutUsDe.json";
import aboutUsEn from "./aboutUs/aboutUsEn.json";

import contactDe from "./contact/contactDe.json";
import contactEn from "./contact/contactEn.json";

import faqDe from "./faq/faqDe.json";
import faqEn from "./faq/faqEn.json";

import dataPolicyDe from "./dataPolicy/dataPolicyDe.json";
import dataPolicyEn from "./dataPolicy/dataPolicyEn.json";

import imprintDe from "./imprint/imprintDe.json";
import imprintEn from "./imprint/imprintEn.json";

import termsDe from "./terms/termsDe.json";
import termsEn from "./terms/termsEn.json";

export const infoPagesDe = [
  aboutUsDe,
  contactDe,
  faqDe,
  imprintDe,
  dataPolicyDe,
  termsDe,
];
export const infoPagesEn = [
  aboutUsEn,
  contactEn,
  faqEn,
  imprintEn,
  dataPolicyEn,
  termsEn,
];

export const infoPages: { [key: string]: any } = {
  de: infoPagesDe,
  en: infoPagesEn,
};

export const getInfoPages = (lang: string) => {
  console.log(infoPages, lang);
  if (
    lang === "en-US" ||
    lang === "en" ||
    lang === "en-GB" ||
    lang === "en-EN"
  ) {
    return infoPages.en;
  }
  if (lang === "de-De" || lang === "de-DE") {
    return infoPages.de;
  }

  if (infoPages[lang]) {
    return infoPages[lang];
  }

  return infoPages.de;
};
