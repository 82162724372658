import { selectorFamily } from "recoil";

import { findDupeProductsRequest } from "../../api/findDupeProductsRequest";
import { captureException } from "@sentry/react";

export const findDupeProductsSelector = selectorFamily({
  key: "findDupeProductsSelector",
  get: (gtin?: string | null) => async () => {
    if (!gtin) return [];
    try {
      const dupeProducts = await findDupeProductsRequest(gtin);

      return dupeProducts;
    } catch (error) {
      captureException(error);
      return [];
    }
  },
});
