import React from "react";

interface HeroImageSectionProps {
  title: string;
  image: string;
}

export const HeroImageSmallSection: React.FC<HeroImageSectionProps> = ({
  title,
  image,
}) => {
  return (
    <section className="relative h-96 w-full overflow-hidden ">
      <img
        alt={title}
        src={image}
        className="h-96 w-full scale-150 object-contain object-center"
      />
      <div className="absolute inset-0 flex items-center justify-center px-6">
        <h1 className="mr-2 text-center font-ivypresto-regular text-4xl text-white ">
          {title}
        </h1>
        <img
          className={`h-12  object-contain`}
          src="/matched_logo.png"
          alt="logo"
        />
      </div>
    </section>
  );
};
