import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { ISelectedMatch } from "../../../../state/selectors/matchingDupesSelector";
import { MatchedCosmos } from "./MatchedCosmos";
import { t } from "i18next";
import { trackMatchCosmosOpenend } from "../../../../Tracking";
import { useEffect, useState } from "react";

type TAccordionProps = {
  match: ISelectedMatch | null;
};

export function ProductDetailAccordion({ match }: TAccordionProps) {
  const [stateBefore, setStateBefore] = useState(false);
  return (
    <div className="divide-gray-900/10 mx-auto max-w-4xl">
      <dl className="divide-gray-900/10 mt-10 space-y-6 ">
        <Disclosure as="div" className="">
          {({ open }) => {
            useEffect(() => {
              if (open && !stateBefore && match?.data) {
                trackMatchCosmosOpenend(match?.data);
                setStateBefore(true);
              }
            }, [open]);

            return (
              <>
                <dt>
                  <Disclosure.Button
                    className={
                      "text-gray-900 flex w-full items-start justify-between border-t  border-gray-200 pt-8 text-left"
                    }
                    data-cy="product-detail-match-cosmos-accordion-button"
                  >
                    <span className="font-circular-medium text-lg  leading-7">
                      Matched Cosmos
                    </span>
                    <span className="ml-6 flex h-7 items-center">
                      {open ? (
                        <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                      ) : (
                        <ChevronDownIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </span>
                  </Disclosure.Button>
                </dt>
                <Disclosure.Panel as="dd" className="mt-2 ">
                  <p className="text-gray-600 text-base leading-7">
                    <MatchedCosmos />
                  </p>
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
        <Disclosure as="div" className="">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button
                  className={
                    "text-gray-900 flex w-full items-start justify-between border-t  border-gray-200 pt-8 text-left"
                  }
                >
                  <span className="font-circular-medium text-lg  leading-7">
                    {t("PRODUCT_DETAIL_PAGE.INGREDIENTS")}
                  </span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <ChevronUpIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <ChevronDownIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-4 pr-12 text-left">
                <p className="text-left font-circular-book text-sm  leading-7 text-gray-300 ">
                  {match?.data.ingredient_de || t("SHARED.INCIS_NOT_FOUND")}
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </dl>
    </div>
  );
}
