import { useEffect, useMemo, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import { findProductByGtinRequest } from "../../../../api/findProductByGtinRequest";
import { ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ProductNotFoundModal } from "./components/ProductNotFoundModal";
import { useSDK } from "../../../../thridparty/scandit/sdk";
import type {
  BarcodeCapture,
  BarcodeCaptureListener,
  BarcodeCaptureSession,
} from "scandit-web-datacapture-barcode";
import { track, trackInstoreMatch } from "../../../../Tracking";
import { useSetRecoilState } from "recoil";

export const InstoreScannerMatchPage = () => {
  const { loaded, sdk } = useSDK();
  const host = useRef<HTMLDivElement | null>(null);
  // const barcodeScanner = useRef<BarcodePicker | null | undefined>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [productNotFoundModalOpen, setProductNotFoundModalOpen] =
    useState(false);

  // const removeCameraAccess = async () => {
  //   barcodeScanner.current?.pauseScanning(true);
  //   // barcodeScanner.current?.clearSession();
  //   // barcodeScanner?.current?.destroy(true);
  // };

  const onOverlayClose = async (route: any) => {
    await sdk.enableCamera(false);
    navigate(route);
  };

  // const onScanResult = async (gtin: string) => {
  //   try {
  //     if (gtin.length < 1) return;
  //     const product = await findProductByGtinRequest(gtin);
  //     if (!product) {
  //       setProductNotFoundModalOpen(true);
  //       return;
  //     } // TODO add popup

  //     // await removeCameraAccess();
  //     navigate("/de/dupes/" + product.gtin);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  const closeNotFoundModal = async () => {
    await sdk.enableScanning(true);
    setProductNotFoundModalOpen(false);
  };

  const onScan = useMemo<BarcodeCaptureListener>(
    () => ({
      didScan: async (_: BarcodeCapture, session: BarcodeCaptureSession) => {
        if (session.newlyRecognizedBarcodes.length > 0) {
          await sdk.enableScanning(false);
          const gtin = session.newlyRecognizedBarcodes[0].data;
          const product = await findProductByGtinRequest(gtin!);

          if (!product) {
            setProductNotFoundModalOpen(true);
            // await shouldKeepCameraOn();
            return;
          }
          await sdk.enableCamera(false);
          trackInstoreMatch(product);
          navigate("/de/dupes/" + product.gtin);
          // setBarcode(session.newlyRecognizedBarcodes[0]);
          // navigate("/result");
        }
      },
    }),
    [sdk]
  );

  // useEffect(() => {
  //   console.log("Requesting camera access");
  //   requestCameraAccess().then(async () => {
  //     console.log("again");
  //     barcodeScanner.current = await configureScanner(
  //       ref.current,
  //       onScanResult
  //     );
  //   });
  // }, []);

  useEffect(() => {
    async function onMount(): Promise<void> {
      if (loaded && host.current) {
        sdk.connectToElement(host.current);
        await sdk.enableCamera(true);
        await sdk.enableScanning(true);
        sdk.addBarcodeCaptureListener(onScan);
      }
    }

    void onMount();
    return () => {
      if (loaded) {
        sdk.removeBarcodeCaptureListener(onScan);
        sdk.detachFromElement();
      }
    };
  }, [loaded, sdk, onScan]);

  return (
    <div>
      <div className="absolute left-1/2 top-8 z-10 -translate-x-1/2 font-circular-book text-xl text-white">
        InStore Match
      </div>
      <button
        type="button"
        className=" absolute  top-4 left-4 z-10 rounded-full p-4 focus:outline-none"
        onClick={() => onOverlayClose(-1)}
      >
        <span className="sr-only">Close panel</span>
        <ChevronLeftIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>
      <button
        type="button"
        className="absolute top-4  right-4 z-10 rounded-full p-4 focus:outline-none"
        onClick={() => onOverlayClose("/")}
      >
        <span className="sr-only">Close panel</span>
        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
      </button>

      <div
        className="absolute top-1/2 left-1/2 z-10 h-60 w-60 -translate-x-1/2 -translate-y-1/2 transform  border-2
        border-white"
      />
      <div
        id="scandit-barcode-picker"
        ref={host}
        className="h-screen w-screen  sm:w-full"
      ></div>

      <ProductNotFoundModal
        isOpen={productNotFoundModalOpen}
        onClose={closeNotFoundModal}
      />
    </div>
  );
};
