import { useTranslation } from "react-i18next";
import { classNames } from "../../../../../shared";
import { TSearchTypeFilter } from "../../../../../types";

type TFilterPillProps = {
  filter: TSearchTypeFilter;
  onClick: (selectedValue?: string) => void;
};

export const FilterButtonPill = ({ filter, onClick }: TFilterPillProps) => {
  const { t } = useTranslation();
  return (
    <button
      data-cy={`filter-${filter.label.toLowerCase()}`}
      onClick={() => onClick()}
      className={classNames(
        " mr-4 flex  transform rounded border border-black py-2 px-4 font-circular-book text-sm  uppercase text-black transition-transform active:scale-95",
        filter.selected ? "bg-black text-white" : "bg-white text-black"
      )}
    >
      <span className="whitespace-nowrap">{t(filter.label)}</span>
    </button>
  );
};
