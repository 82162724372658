import { TProduct } from "../../types";
import {
  gaTrackProductClick,
  gaTrackShopLinkClick,
  gaTrackUserRegistered,
} from "../google";
import { teTrackProductClick, trackAccountCreateComplete } from "../tealium";

// Use this file for all tracking events which are send to multiple tracking services

export const trackUserRegistered = (userId: string, method: string) => {
  gaTrackUserRegistered(method);
  trackAccountCreateComplete(userId);
  // sendGAConversion("iBIOCI60y5kYEOfd3oQp");

  if (window?.ttq?.track) {
    window.ttq.track("SubmitForm", {});
  }

  if (window?.fbq) {
    window.fbq("track", "Lead", {});
  }
};

export const trackProductClick = (
  product: TProduct,
  productPosition: number,
  pageContext: string
) => {
  gaTrackProductClick(product.gtin);
  teTrackProductClick(product, productPosition, pageContext);
};

export const trackShopLinkClick = (shopLink: string) => {
  gaTrackShopLinkClick(shopLink);
};
