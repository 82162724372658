import { selectorFamily } from "recoil";
import { findProductByGtinRequest } from "../../api/findProductByGtinRequest";

import { selectedDupeGtinAtom } from "../atoms/selectedDupeGtinAtom";
import { shownSearchResultsSelector } from "./shownSearchResultsSelector";

export const selectedProductToDupeSelector = selectorFamily({
  key: "selectedProductToDupeSelector",
  get:
    (selectedGtin?: string | null | undefined) =>
    async ({ get }) => {
      const gtin = selectedGtin ? selectedGtin : get(selectedDupeGtinAtom);

      if (!gtin) return null;

      const availableProducts = get(shownSearchResultsSelector);

      const product = availableProducts.products.find(
        (product) => product.gtin === gtin
      );

      if (product) return product;

      const remoteProduct = await findProductByGtinRequest(gtin);

      if (remoteProduct) return remoteProduct;

      return null;
    },
});
