export const GoogleLogo = () => {
  return (
    <svg
      width="32"
      height="31"
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M31.3806 12.381H30.1404V12.3171H16.2837V18.4757H24.985C23.7155 22.0607 20.3045 24.6342 16.2837 24.6342C11.1821 24.6342 7.04588 20.498 7.04588 15.3964C7.04588 10.2948 11.1821 6.15855 16.2837 6.15855C18.6386 6.15855 20.781 7.04692 22.4122 8.49803L26.7671 4.14317C24.0173 1.58044 20.3391 0 16.2837 0C7.78106 0 0.887329 6.89373 0.887329 15.3964C0.887329 23.899 7.78106 30.7928 16.2837 30.7928C24.7864 30.7928 31.6801 23.899 31.6801 15.3964C31.6801 14.3641 31.5739 13.3564 31.3806 12.381Z"
        fill="#FFC107"
      />
      <path
        d="M2.81192 7.95951L7.86038 11.5473C9.22641 8.26997 12.5347 5.95605 16.4061 5.95605C18.7563 5.95605 20.8945 6.81521 22.5225 8.2186L26.8688 4.00693C24.1244 1.52847 20.4535 0 16.4061 0C10.5041 0 5.38571 3.22892 2.81192 7.95951Z"
        fill="#FF3D00"
      />
      <path
        d="M16.5344 30.7928C20.5158 30.7928 24.1335 29.2418 26.8688 26.7197L22.0981 22.6105C20.4985 23.8487 18.5439 24.5184 16.5344 24.5168C12.5251 24.5168 9.12093 21.9146 7.83847 18.2832L2.81192 22.2253C5.36296 27.3065 10.5437 30.7928 16.5344 30.7928Z"
        fill="#4CAF50"
      />
      <path
        d="M31.3807 12.5734H30.1405V12.5098H16.2838V18.6427H24.985C24.3778 20.3419 23.284 21.8266 21.8388 22.9136L21.8411 22.912L26.6063 26.9276C26.2691 27.2327 31.6801 23.2424 31.6801 15.5762C31.6801 14.5482 31.5739 13.5447 31.3807 12.5734Z"
        fill="#1976D2"
      />
    </svg>
  );
};
