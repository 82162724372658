import { getRequest } from "./getRequest";

export const checkIfUserIsSubscribed = async (): Promise<boolean> => {
  try {
    const searchUrl = `${
      import.meta.env.VITE_BACKEND_URL
    }/checkUserHasSubscription`;

    const isSubscribed = await getRequest(searchUrl);
    return isSubscribed;
  } catch (error) {
    console.log(error); // TODO show this to the user
    return false;
  }
};
