export const getRequest = async (url: string) => {
  const response = await fetch(url);

  if (!response.ok || response.status !== 200) {
    throw new Error(`Error: ${response.status} ${response.statusText}`);
  }

  const data = await response.json();
  return data;
};
