import React from "react";
import { useSearchParams } from "react-router-dom";
import { LoginModal } from "./LoginModal";
import { Button } from "../../components/buttons/Button";
import { RegisterModal } from "./RegisterModal";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { PasswordResetSendModal } from "./PasswordResetSendModal";
import {
  RegisterMethod,
  ThankYouForRegisteringModal,
} from "./ThankYouForRegisteringModal";
import { t } from "i18next";

enum EShownModal {
  LOGIN = "login",
  REGISTER = "register",
  FORGOT = "forgot",
  NONE = "none",
  RESET_SEND = "reset_send",
  THANK_YOU = "thank_you",
  THANK_YOU_SOCIAL = "thank_you_social",
}

export const AuthPage: React.FC = () => {
  // const [shownModal, setShownModal] = useState<EShownModal>(EShownModal.NONE);
  const [searchParams, setSearchParams] = useSearchParams();

  const shownModal: EShownModal | null = searchParams.get(
    "modal"
  ) as EShownModal;

  return (
    <div className="flex w-full items-center justify-center ">
      <div
        className="flex min-h-screen w-full max-w-xl flex-col justify-between bg-cover bg-top  bg-no-repeat px-5 pb-11 pt-20"
        style={{
          backgroundImage: "url(/v2/images/bg-setting.png)",
        }}
      >
        <img
          className={` h-40 w-full object-contain`}
          src="/matched_logo.png"
          alt="logo"
        />
        <div className="flex w-full flex-col justify-between">
          <Button
            testId="open-register-modal-button"
            text={t("AUTH_PAGE.REGISTER_BUTTON")}
            onClick={() => setSearchParams({ modal: EShownModal.REGISTER })}
          />
          <div className="mt-4">
            <Button
              testId="open-login-modal-button"
              text="Login"
              styleType="secondary"
              onClick={() => setSearchParams({ modal: EShownModal.LOGIN })}
            />
          </div>
          <div className="mt-14 flex w-full items-center justify-center">
            <span>
              <a
                href={"https://matched.com/"}
                className="font-circular-book text-base text-white underline"
              >
                Matched.com
              </a>
            </span>
          </div>
        </div>
      </div>
      <div>
        <ForgotPasswordModal
          isOpen={shownModal === EShownModal.FORGOT}
          onClose={() => setSearchParams({ modal: EShownModal.NONE })}
        />

        <RegisterModal
          isOpen={shownModal === EShownModal.REGISTER}
          onClose={() => setSearchParams({ modal: EShownModal.NONE })}
        />

        <LoginModal
          isOpen={shownModal === EShownModal.LOGIN}
          onClose={() => setSearchParams({ modal: EShownModal.NONE })}
        />

        <PasswordResetSendModal
          isOpen={shownModal === EShownModal.RESET_SEND}
          onClose={() => setSearchParams({ modal: EShownModal.NONE })}
        />

        <ThankYouForRegisteringModal
          isOpen={
            shownModal === EShownModal.THANK_YOU ||
            shownModal === EShownModal.THANK_YOU_SOCIAL
          }
          registerMethod={
            shownModal === EShownModal.THANK_YOU_SOCIAL
              ? RegisterMethod.Socail
              : RegisterMethod.Email
          }
          onClose={() => setSearchParams({ modal: EShownModal.NONE })}
        />
      </div>
    </div>
  );
};
