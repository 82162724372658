import { getAnalytics, logEvent } from "firebase/analytics";
import { TGaEventParameters } from "../../types";

const analytics = getAnalytics();

export function sendGAEvent(
  eventName: string,
  parameters: TGaEventParameters
): void {
  if (typeof window === undefined || !window.gtag) {
    return;
  }

  logEvent(analytics, eventName, parameters);

  // window.gtag("event", eventName, parameters);
}

// export function sendGAConversion(id: string) {
//   // const callback = function () {
//   //   if (typeof url != "undefined") {
//   //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   //     // @ts-ignore
//   //     window.location = url;
//   //   }
//   // };+
//   if (typeof window === undefined || !window.gtag) {
//     return;
//   }

//   window?.gtag("event", "conversion", {
//     send_to: `AW-11015794407/${id}`,
//     transaction_id: "",
//     event_callback: () => {},
//   });
//   return false;
// }
