import { useRef, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { searchSuggestionsSelector } from "../../../../state/selectors/searchSuggestionsSelector";
import {
  gaTrackSearchBarClick,
  trackSeachSuggestionClicked,
} from "../../../../Tracking";
import { classNames } from "../../../../shared";
import {
  searchPaginationAtom,
  searchStringFilterAtom,
} from "../../../../state/atoms";
import { useTranslation } from "react-i18next";
import { useTrackSearch } from "../../../../hooks/useTrackSearch";

type TSearchBarProps = {
  onSearchChanged?: (newValue: string) => void;
};

export function SearchBar({ onSearchChanged }: TSearchBarProps): JSX.Element {
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const trackedClickBefore = useRef(false);
  const setSearchPagination = useSetRecoilState(searchPaginationAtom);
  const [searchStringFilter, setSearchStringFilter] = useRecoilState(
    searchStringFilterAtom
  );

  const searchSuggestions =
    useRecoilValue(searchSuggestionsSelector(query)) || [];

  const onSearchBarClicked = () => {
    if (trackedClickBefore.current) return;
    gaTrackSearchBarClick();
    trackedClickBefore.current = true;
  };

  // TODO add filter tracking
  useTrackSearch(searchStringFilter);

  const onSearchStringFilterChange = (newValue: string) => {
    onSearchChanged && onSearchChanged(newValue);
    setSearchPagination(1);
    setSearchStringFilter(newValue);
    setQuery(newValue);
  };

  const autoFocused = searchStringFilter.length !== 0;

  return (
    <>
      <Combobox
        as="div"
        value={searchStringFilter}
        onChange={onSearchStringFilterChange}
      >
        <div className="relative ">
          <Combobox.Button className="absolute top-4 left-0 ml-4 flex items-center rounded-l-full focus:outline-none ">
            <MagnifyingGlassIcon
              className="h-6 w-6 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>
          <Combobox.Input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={autoFocused}
            data-cy="search-bar"
            className=" text-gray-900 block h-14 w-full rounded border-0 bg-gray-200 py-2 pl-14 pr-14 ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
            onChange={(event) => {
              setQuery(event.target.value);
              onSearchStringFilterChange(event.target.value);
            }}
            onKeyDown={(event: any) => {
              if (event.key === "Enter") {
                event.target.blur();
                onSearchStringFilterChange(event.target.value);
                setAutoCompleteOpen(false);
                event.preventDefault();
              }
            }}
            onClick={onSearchBarClicked}
            onFocus={() => setAutoCompleteOpen(true)}
            placeholder={t("SHARED.SEARCH_BAR_PLACEHOLDER")}
            displayValue={(word: string) => word}
          />
          {searchStringFilter.length > 0 && (
            <Combobox.Button
              data-cy="search-bar-clear-button"
              className="absolute top-0 right-0 mr-0 flex h-14 items-center  p-2 focus:outline-none "
              onClick={() => onSearchStringFilterChange("")}
            >
              <XMarkIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          )}

          {autoCompleteOpen && searchSuggestions.length > 0 && (
            <Combobox.Options
              data-cy="auto-suggestion-list"
              className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {searchSuggestions.map((word, index) => (
                <Combobox.Option
                  data-cy="auto-suggestion-item"
                  key={index}
                  value={word.trim()}
                  onClick={() => trackSeachSuggestionClicked(word)}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-black" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected ? "font-semibold" : ""
                        )}
                      >
                        {word.trim()}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-black" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </>
  );
}
