import { TDupeProduct } from "../types";
import { getRequest } from "./getRequest";

export const findDupeProductsRequest = async (
  gtin: string
): Promise<TDupeProduct[]> => {
  try {
    const searchUrl = `${
      import.meta.env.VITE_BACKEND_URL
    }/findTwinProducts/${gtin}`;

    const searchProducts = await getRequest(searchUrl);

    return searchProducts;
  } catch (error) {
    console.log(error); // TODO show this to the user
    return [];
  }
};
