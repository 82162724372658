import { TDupeProduct, TProduct } from "../../../types";
import { auth } from "../auth";
import { db } from "../firebaseInit";

import { doc, setDoc, getDoc } from "firebase/firestore";

const collectionId = "userActivity";

type TDiscountedDupe = {
  originGtin: string;
  dupeGtin: string;
  priceDifference: number;
  lastSeen: string;
};

const recordedAlreadyFor: string[] = [];

export async function saveLastSeenDupesWithHighestDiscount(
  dupes: TDupeProduct[],
  originalProduct: TProduct
): Promise<void> {
  if (recordedAlreadyFor.includes(originalProduct.gtin)) {
    return;
  }

  const dupesWithPriceDifference = getDupesWithHighestDiscount(
    dupes,
    originalProduct
  );
  saveToFirebase(dupesWithPriceDifference, originalProduct);
  recordedAlreadyFor.push(originalProduct.gtin);
}

function getDupesWithHighestDiscount(
  dupes: TDupeProduct[],
  originalProduct: TProduct
): TDiscountedDupe[] {
  const discountedDupes = dupes.reduce((acc: TDiscountedDupe[], dupe) => {
    const priceDifference = calculatePercentDifference(
      Number(dupe.data.price_de),
      Number(originalProduct.price_de)
    );

    const discountedDupe = {
      originGtin: originalProduct.gtin,
      dupeGtin: dupe.gtin_twinproduct,
      priceDifference,
      lastSeen: new Date().toISOString(),
    };

    acc.push(discountedDupe);

    return acc;
  }, []);

  return discountedDupes;
}

function calculatePercentDifference(num1: number, num2: number): number {
  if (isNaN(num1) || isNaN(num2)) {
    throw new Error(
      "Invalid input. Please provide valid numbers in string format."
    );
  }

  const difference = num2 - num1;
  const percentDifference = (difference / num1) * 100;

  return Math.round(percentDifference * 100) / 100; // Round to two decimal places
}

async function saveToFirebase(
  newDupesWithPriceDifferences: TDiscountedDupe[],
  originalProduct: TProduct
): Promise<void> {
  try {
    const userId = await auth.currentUser?.uid;
    const email = await auth.currentUser?.email;

    if (!userId || !email) {
      return;
    }

    const docRef = doc(db, collectionId, userId);

    const lastSeenProduct = {
      gtin: originalProduct.gtin,
      price: originalProduct.price_de,
      timestamp: Date.now(),
    };

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      let payload = {};

      if (
        !data.lastSeenDupesWithHighestDiscount ||
        data.lastSeenDupesWithHighestDiscount.length === 0
      ) {
        payload = {
          lastSeenDupesWithHighestDiscount: getTopFourPriceDifferences(
            newDupesWithPriceDifferences
          ),
        };
      } else {
        payload = {
          lastSeenDupesWithHighestDiscount: getTopFourPriceDifferences([
            ...data.lastSeenDupesWithHighestDiscount,
            ...newDupesWithPriceDifferences,
          ]),
        };
      }

      if (!data.lastSeenProducts || data.lastSeenProducts.length === 0) {
        payload = { lastSeenProducts: [lastSeenProduct], ...payload };
      } else {
        payload = {
          lastSeenProducts: filterRecentEntries(data?.lastSeenProducts, [
            lastSeenProduct,
          ]),
          ...payload,
        };
      }

      await setDoc(doc(db, collectionId, userId), {
        ...payload,
        email: email,
      });
      return;
    }

    await setDoc(doc(db, collectionId, userId), {
      lastSeenDupesWithHighestDiscount: getTopFourPriceDifferences(
        newDupesWithPriceDifferences
      ),
      lastSeenProducts: [lastSeenProduct],
      email: email,
    });
  } catch (error) {
    console.error("Error saving payload:", error);
  }
}

function getTopFourPriceDifferences(
  discountedDupes: TDiscountedDupe[]
): TDiscountedDupe[] {
  const sortedDupes = discountedDupes.sort(
    (a, b) => b.priceDifference - a.priceDifference
  );
  return sortedDupes.slice(0, 4);
}

export async function saveLastSeenProduct(payload: any): Promise<void> {
  try {
    const userId = await auth.currentUser?.uid;

    if (!userId) {
      return;
    }

    const docRef = doc(db, collectionId, userId);

    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      await setDoc(doc(db, collectionId, userId), {
        lastSeenProducts: filterRecentEntries(data?.history, [payload]),
      });
      return;
    }

    await setDoc(doc(db, collectionId, userId), {
      lastSeenProducts: [payload],
    });
  } catch (error) {
    console.error("Error saving payload:", error);
  }
}

// order the last seen products by timestamp and return the 4 most recent entries
function filterRecentEntries(arr1: any[], arr2: any[]): any[] {
  const mergedArray = [...arr1, ...arr2];

  mergedArray.sort((a, b) => b.timestamp - a.timestamp);
  return mergedArray.slice(0, 4);
}
