import { useNavigate } from "react-router-dom";
import { classNames } from "../../../../shared";
import { Button } from "../../../components/buttons/Button";

interface TitleShowCaseSectionProps {
  title: string;
  description: string;
  image: string;
  bgColor: string;
  subTitle?: string;
  callToAction?: string;
}

export const NumberShowCaseSection = ({
  title,
  description,
  image,
  bgColor,
  subTitle,
  callToAction,
}: TitleShowCaseSectionProps) => {
  const naviagte = useNavigate();
  const onPickColor = () => {
    naviagte("/");
  };

  return (
    <section className={classNames("flex flex-col pt-8", bgColor)}>
      <div className="flex w-full justify-center px-20">
        <img
          className={`h-8  object-contain`}
          src="/matched_logo_black.png"
          alt="logo"
        />
      </div>
      <div className=" mb-2 w-full text-center">
        <span className="mb-4 font-circular-book leading-9 text-black">
          {subTitle}
        </span>
      </div>
      <div className="relative h-full w-full px-4">
        <img src={image} alt={title} className="w-full rounded-xl " />
      </div>
      <div className="mt-4 mb-2 flex w-full justify-center text-center">
        <div className=" flex  w-48 justify-between">
          <div>
            <h2 className="font-ivypresto-regular text-3xl leading-9 text-black">
              90+
            </h2>
            <span className="font-circular-book">Brands</span>
          </div>

          <div>
            <h2 className="font-ivypresto-regular text-3xl leading-9 text-black">
              100.000+
            </h2>
            <span className="font-circular-book">Dupes</span>
          </div>
        </div>
      </div>
      <div className="mt-4 flex w-full justify-center">
        <div className="w-40">
          <Button
            styleType="primary"
            text={callToAction}
            onClick={onPickColor}
          />
        </div>
      </div>
    </section>
  );
};
