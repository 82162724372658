import React from "react";
import { classNames } from "../../../../shared";
import { Button } from "../../../components/buttons/Button";

type VideoTextSectionProps = {
  title: string;
  description: string;
  video: string;
  bgColor: string;
  subTitle?: string;
  emphasizedTitle?: string;
};

export const VideoTextSection = ({
  title,
  emphasizedTitle,
  description,
  video,
  bgColor,
  subTitle,
}: VideoTextSectionProps) => {
  return (
    <section className={classNames("flex flex-col py-4", bgColor)}>
      <div className="mb-4 w-full px-28 text-center">
        <h2
          className={classNames(
            "mb-1 font-ivypresto-regular text-3xl leading-9"
          )}
        >
          {title}
        </h2>
        {emphasizedTitle && (
          <h2 className="mb-4 font-lakeshore-regular text-5xl uppercase  leading-9 tracking-wide">
            {emphasizedTitle}
          </h2>
        )}
      </div>
      <div className="relative w-full overflow-hidden px-4">
        <video
          muted
          autoPlay
          loop
          playsInline
          src={video}
          className="h-96 overflow-hidden rounded-xl  object-cover object-top"
        />
      </div>
      <div className="mt-4 px-4">
        <h2 className="mb-2 text-center font-circular-book text-xl">
          {subTitle}
        </h2>
        <p className="mb-4 text-center font-circular-book text-base text-gray-300">
          {description}
        </p>
      </div>
    </section>
  );
};
