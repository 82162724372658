import { useFormContext } from "react-hook-form";
import { classNames } from "../../../shared";

interface LabledInputProps {
  labelText: string;
  placeholder?: string;
  testId?: string;
  mode?: "light" | "dark";
  name: string;
  type?: string;
  rules?: any;
  onClick?: () => void;
}

export function LabledInput({
  labelText,
  mode = "dark",
  name,
  testId,
  type = "text",
  placeholder,
  onClick,
  rules,
}: LabledInputProps) {
  const { register } = useFormContext();
  return (
    <div className="relative">
      <label
        htmlFor="name"
        className={classNames(
          "absolute -top-2.5 left-2 inline-block  px-2 font-circular-book text-sm",
          mode === "dark" ? "bg-black text-white" : "bg-white text-black"
        )}
      >
        {labelText}
      </label>
      <input
        type={type}
        id={name}
        onClick={onClick}
        data-cy={testId || `${name}-input`}
        className={classNames(
          " block h-14 w-full rounded-sm border border-white px-4 py-1.5 ring-2  ring-white  focus:ring-inset sm:text-sm sm:leading-6",
          mode === "dark"
            ? "bg-black text-white placeholder:text-white"
            : "text-gray-900 bg-white placeholder:text-gray-400"
        )}
        placeholder={placeholder}
        {...register(name, { ...rules })}
      />
    </div>
  );
}
