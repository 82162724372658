import { atom, selector } from "recoil";

export type TTinyAdminSettings = {
  authModeOn: boolean;
};

const fetchSettings = async () => {
  try {
    const response = await fetch("/v2/api/tinyAdminSettings");
    const settings = await response.json();
    return settings;
  } catch (error) {
    return null;
  }
};

const saveSettings = async (newSettings: TTinyAdminSettings) => {
  try {
    const response = await fetch("/v2/api/tinyAdminSettings", {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newSettings),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    alert(error);
  }
};

export const tinyAdminAtom = atom({
  key: "TinyAdminAtom",
  default: selector({
    key: "tinyAdminSelector",
    get: async () => {
      if (import.meta.env.VITE_TEALIUM_ENVIRONMENT !== "dev") {
        return {
          authModeOn: false,
        };
      }

      return await fetchSettings();
    },
  }),
  effects: [
    ({ onSet }) => {
      onSet(async (newSettings) => {
        const savedSettings = saveSettings(newSettings);
        return savedSettings;
      });
    },
  ],
});
