import { Link } from "react-router-dom";
import HeroImageSection from "./components/HeroImageSection";
import { HeroImageSmallSection } from "./components/HeroImageSmallSection";
import ImageTextSection from "./components/ImageTextSection";
import { NumberShowCaseSection } from "./components/NumberShowCaseSection";
import { TitleShowCaseSection } from "./components/TitleShowCaseSection";
import { VideoTextSection } from "./components/VideoTextSection";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
export const AboutUsPage = () => {
  return (
    <div className="flex w-full items-center justify-center ">
      <div className="flex min-h-screen w-full max-w-xl flex-col justify-between bg-cover bg-top  bg-no-repeat pb-11 ">
        <div className="relative flex h-14 items-center  justify-center">
          <Link
            to="/de/menu"
            className="absolute left-4 active:scale-95"
            data-cy="info-page-back-button"
          >
            <ChevronLeftIcon className="h-6 w-6" />
          </Link>
          <div className="flex w-full items-center justify-center  ">
            <h1 className=" font-ivypresto-regular  text-2xl">About Us</h1>
          </div>
        </div>
        <HeroImageSection
          title={"TECH KISSED BEAUTY"}
          image={"/v2/images/aboutUs/GettyImages-1437853884-1.png"}
        />
        <div className="mt-6">
          <ImageTextSection
            oneLine
            title="WE ARE"
            titleIcon="/matched_logo_black.png"
            description={t("ABOUT_US_PAGE.WE_ARE_DESCRIPTION")}
            image={"/v2/images/aboutUs/240119_CO_matched11-1-3.png"}
            bgColor="bg-white"
          />
        </div>
        <div className="mt-8">
          <VideoTextSection
            title={t("ABOUT_US_PAGE.RE_INVENT_BEAUTY")}
            description={t("ABOUT_US_PAGE.RE_INVENT_BEAUTY_DESCRIPTION")}
            video={"/v2/images/aboutUs/reinventbeauty.mp4"}
            bgColor={"bg-gray-100"}
            subTitle={""}
          />
        </div>

        <TitleShowCaseSection
          title={"The easiest way to find your perfect"}
          emphasizedTitle="MATCH"
          description={""}
          image={"/v2/images/aboutUs/easiestway.png"}
          bgColor={"bg-darker-purple"}
        />

        <div className="mt-8">
          <ImageTextSection
            title="FIND YOUR TRUE COLOR"
            description={t("ABOUT_US_PAGE.TRUE_COLOR_DESCRIPTION")}
            subTitle={t("ABOUT_US_PAGE.TRUE_COLOR_SUBTITLE")}
            image={"/v2/images/aboutUs/240119_CO_matched11-1-4.png"}
            bgColor="bg-white"
          />
        </div>

        <div className="mt-8">
          <VideoTextSection
            title={"TECH KISSED BEAUTY"}
            description={t("ABOUT_US_PAGE.TECH_KISSED_DESCRIPTION")}
            video={"/v2/images/aboutUs/776f-4eee-a13e-7390a7867d1d.mp4"}
            bgColor={"bg-white"}
            subTitle={t("ABOUT_US_PAGE.TECH_KISSED_SUBTITLE")}
          />
        </div>
        <div className="">
          <HeroImageSection
            title={"OUR TRUE COLORS"}
            image={"/v2/images/aboutUs/4db8a06f0bc02f77d8ac751e01986e20.png"}
          />
        </div>
        <div className="mt-8">
          <VideoTextSection
            title={"WE BUILD "}
            emphasizedTitle={"WISDOM"}
            description={t("ABOUT_US_PAGE.WISDOM_DESCRIPTION")}
            video={"/v2/images/aboutUs/webuildwisdom.mp4"}
            bgColor={"bg-gray-100"}
          />
        </div>
        <div className="mt-4">
          <ImageTextSection
            emphasizedTitle={"CLARITY"}
            title="WE CREATE "
            description={t("ABOUT_US_PAGE.CLARITY_DESCRIPTION")}
            subTitle=""
            image={"/v2/images/aboutUs/clarity.gif"}
            bgColor="bg-white"
          />
        </div>
        <div className="mt-8">
          <ImageTextSection
            emphasizedTitle={"ORIGINALITY"}
            title="WE CELEBRATE "
            description={t("ABOUT_US_PAGE.ORIGINALITY_DESCRIPTION")}
            subTitle=""
            image={"/v2/images/aboutUs/240119_CO_matched11-1-6.png"}
            bgColor="bg-white"
          />
        </div>
        <div className="-mt-8">
          <HeroImageSmallSection
            title={"ITS A "}
            image={"/v2/images/aboutUs/240119_CO_matched12.png"}
          />
        </div>
        <NumberShowCaseSection
          title={""}
          callToAction="Match me"
          description={""}
          subTitle={t("ABOUT_US_PAGE.FIND_DESCRIPTION")}
          image={"/v2/images/aboutUs/Image-8.png"}
          bgColor={""}
        />
      </div>
    </div>
  );
};
