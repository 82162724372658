import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { BottomModal } from "../../../components/modals/BottomModal";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { formatPrice } from "../../../utils/formatPrice";
import { filterShownShops } from "../utils/filterShownShops";
import { trackShopLinkClick } from "../../../../Tracking/general/generalTrackingEvents";
import { TDupeProduct, TShopData } from "../../../../types";

// https://localhost:5173/de/3348901408691/match-details/4021457645541
type TAffiliateShopsModalProps = {
  match: TDupeProduct | null;
  onClose: () => void;
};

export const AffiliateShopsModal = ({
  match,
  onClose,
}: TAffiliateShopsModalProps) => {
  if (!match || !match.shops_data) return <></>;

  const shownShops = filterShownShops(match.shops_data);

  return (
    <BottomModal
      isOpen={!!match}
      onClose={onClose}
      testId="affliate-shops-modal"
    >
      <Dialog.Panel className=" w-screen rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
        <div
          className="absolute right-0 top-4  pr-5 pt-4 sm:block"
          data-cy="affiliate-shops-modal"
        >
          <button
            data-cy="close-affiliate-shops-modal"
            type="button"
            className="rounded-md   text-white ring-0 hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8 ring-0" aria-hidden="true" />
          </button>
        </div>

        <div>
          <div className="mt-8 mb-5 flex w-full items-center justify-center ">
            <div className="flex flex-col">
              <h2 className="font-circular-medium text-2xl text-white">
                {match.data.brand}
              </h2>
              <div>
                <span className="ml-2 flex  px-12 font-circular-book text-white">
                  {match.data.namecomplete}
                </span>
              </div>
            </div>
          </div>
          <div className="mt-12 flex w-full items-center justify-center">
            <ul className="w-full ">
              <li className="w-full ">
                {shownShops.map((shop: TShopData, index: number) => (
                  <div key={index} className="flex w-full  justify-between">
                    <span className="font-circular-book text-white">
                      {shop.retailer}
                    </span>
                    <div className="mb-4 flex flex-col">
                      <a
                        href={shop.url}
                        onClick={() => trackShopLinkClick(shop.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-baseline text-right font-circular-book text-base text-white"
                      >
                        <span className="mr-2 text-right underline">
                          {t("SHARED.BUY_PRODUCT_BUTTON_LABEL")}
                        </span>
                        <div>
                          <ArrowTopRightOnSquareIcon className="inline h-6 w-6 text-white" />
                        </div>
                      </a>
                      <span
                        className="w-full text-left font-circular-book  text-xs text-white"
                        data-cy="shop-price"
                      >
                        {t("SHARED.FROM_LABEL")}
                        <span>&sup1;</span> {formatPrice(String(shop.price_de))}{" "}
                        €
                      </span>
                    </div>
                  </div>
                ))}
              </li>
            </ul>
          </div>
        </div>
      </Dialog.Panel>
    </BottomModal>
  );
};
