import { Link, useNavigate } from "react-router-dom";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { SearchBar } from "../search/components/SearchBar";
import { CategoryDiscoverSection } from "./components/CategoryDiscoverSection";
import FeatureMatchesSection from "./components/FeatureMatchesSection";
import { RecentlyReleasedSection } from "./components/RecentlyReleasedSection";
import { Suspense, useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { searchStringFilterAtom } from "../../../state/atoms";
import { RecentlyReleasedSectionLoadingSkeleton } from "./components/RecentlyReleasedSectionLoadingSkeleton";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { TutorialSection } from "./components/TutorialSection";

export const HomePage = () => {
  const navigate = useNavigate();

  const setSearchQuery = useSetRecoilState(searchStringFilterAtom);

  useEffect(() => {
    setSearchQuery("");
  }, [setSearchQuery]);

  const onSearchChanged = () => {
    navigate("/de/suche");
  };
  return (
    <div
      className="flex w-full items-center justify-center"
      data-cy="home-page"
    >
      <div className="flex min-h-screen w-full max-w-xl flex-col pb-11 pt-6">
        <div className="relative mb-5 flex items-center  justify-center px-5">
          <Link to="/de/menu" className="absolute left-5 active:scale-95 ">
            <Bars3Icon className="h-8 w-8" />
          </Link>
          <img
            className={`h-8  object-contain`}
            src="/matched_logo_black.png"
            alt="logo"
          />
        </div>
        <div className="px-5">
          <Suspense
            fallback={
              <div className="h-14 w-full animate-pulse rounded bg-gray-200"></div>
            }
          >
            <SearchBar onSearchChanged={onSearchChanged} />
          </Suspense>
        </div>

        <div className="mt-7 px-5">
          <FeatureMatchesSection />
        </div>
        <div className="mt-12 px-5">
          <Suspense fallback={<RecentlyReleasedSectionLoadingSkeleton />}>
            <RecentlyReleasedSection />
          </Suspense>
        </div>

        <TutorialSection />
        <div className="mt-12 px-5">
          <CategoryDiscoverSection />
        </div>

        <BottomNavBar />
      </div>
    </div>
  );
};
