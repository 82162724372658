export const getTouchPosition = (event: any, canvas: HTMLCanvasElement) => {
  let x: number;
  let y: number;

  if (event.clientX) {
    const rect = canvas.getBoundingClientRect();
    x = event.clientX - rect.left;
    y = event.clientY - rect.top;
  } else {
    const touch = event.touches[0];
    const rect = canvas.getBoundingClientRect();
    x = touch.clientX - rect.left;
    y = touch.clientY - rect.top;
  }

  return { x, y };
};
