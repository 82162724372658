//...
import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";

export const initSentry = () => {
  if (import.meta.env.VITE_IS_DEVELOPMENT) {
    return;
  }

  Sentry.init({
    dsn: "https://091be180670e065b0599233568a37716@o4506790987300864.ingest.sentry.io/4506790989266944",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

export const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
