import { useEffect, useState } from "react";
import { TProduct } from "../types";
import { trackProductImpression } from "../Tracking";

export const useTrackRecentlyReleasedImpression = (
  recentlyReleased: TProduct[] | null
) => {
  const [wasTracked, setWasTracked] = useState(false);

  useEffect(() => {
    if (recentlyReleased && recentlyReleased?.length > 1 && !wasTracked) {
      trackProductImpression(recentlyReleased, "Start Page", "start_page_list");
      setWasTracked(true);
    }
  }, [recentlyReleased, wasTracked]);
};
