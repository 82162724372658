import { atom, selector } from "recoil";
import { getSearchSuggestionsRequest } from "../../api/getSearchSuggestionsRequest";

export const searchSuggestionsAtom = atom<string[]>({
  key: "searchSuggestionsAtom",
  default: selector({
    key: "searchSuggestionsAtom/Default",
    get: async () => await getSearchSuggestionsRequest(),
  }),
});
