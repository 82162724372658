import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Suspense, useEffect } from "react";
import { PriceMatchToggle } from "./components/PriceMatchToggle";
import { classNames } from "../../../shared";
import { currentlyVisibleMatchAtom } from "../../../state/atoms/currentlyVisibleMatchAtom";
import { MatchSliderLoadingSkeleton } from "./components/MatchSlideLoadingSkeleton";
import { ColorMatchSlider } from "./components/ColorMatchSlider";
import { BottomNavBar } from "../../components/navbar/BottomNavBar/BottomNavBar";
import { ERoutePaths } from "../../../types";
import { matchFilterAtom } from "../../../state/atoms";
import { calculateColorBrightness } from "./utils/calculateColorBrightness";
import { t } from "i18next";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

export const MatchPageForColor = () => {
  const navigate = useNavigate();
  const setMatchFilter = useSetRecoilState(matchFilterAtom);

  const { hex } = useParams();

  const currentBgColor = useRecoilValue(currentlyVisibleMatchAtom);

  useEffect(() => {
    setMatchFilter("match");
  }, []);

  const onGoBack = () => {
    navigate(`${ERoutePaths.COLORMATCH_PICK_CATEGORY}/${hex}`);
  };

  const brightnessValue = calculateColorBrightness(currentBgColor || "");

  const fontColor = brightnessValue > 200 ? "black" : "white";

  return (
    <div
      className={classNames(
        "flex w-full items-center justify-center bg-gray-200 transition-colors duration-500 ease-in-out"
      )}
      style={{
        backgroundColor: currentBgColor || "black",
      }}
    >
      <div className="flex min-h-screen w-full max-w-xl flex-col  pb-11 pt-4">
        <div className="w-full px-5 pt-8">
          <div className="relative flex w-full justify-center">
            <button
              onClick={onGoBack}
              className="absolute left-0 font-circular-medium "
              style={{ color: fontColor || "black" }}
            >
              <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <span
              className="text-center font-circular-book"
              style={{ color: fontColor || "black" }}
            >
              {t("COLOR_MATCH_PAGE.YOUR_COLOR_MATCH")}
            </span>
          </div>

          <div className="mt-7">
            <PriceMatchToggle />
          </div>
        </div>
        <div className="mt-7">
          {" "}
          <Suspense fallback={<MatchSliderLoadingSkeleton />}>
            <ColorMatchSlider />
          </Suspense>
        </div>
        <BottomNavBar />
      </div>
    </div>
  );
};
