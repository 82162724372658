import { Dialog } from "@headlessui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { BottomModal } from "../../../../components/modals/BottomModal";
import { Button } from "../../../../components/buttons/Button";

type Inputs = {
  email: string;
};

export const ProductNotFoundModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const methods = useForm<Inputs>();

  const onSubmit = async () => {
    onClose();
  };

  return (
    <BottomModal
      isOpen={isOpen}
      onClose={onClose}
      testId="forgot-password-bottom-modal"
    >
      <Dialog.Panel className="w-full rounded-t-lg bg-black p-5 pt-4 opacity-95 shadow-xl transition-all">
        <div className="absolute right-0 top-8  pr-4 pt-4 sm:block">
          <button
            data-cy="forgot-password-bottom-modal-close-button focus:outline-none"
            type="button"
            className="rounded-md   text-white hover:text-white "
            onClick={onClose}
          >
            <span className="sr-only">Close</span>
            <XMarkIcon className="h-8 w-8" aria-hidden="true" />
          </button>
        </div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="mt-8 mb-5 flex w-full items-center justify-center ">
              <h2 className="font-circular-medium text-2xl font-medium text-white">
                {t("SCANNER_PAGE.PRODUCT_NOT_FOUND_TITLE")}
              </h2>
            </div>
            <div className="flex w-full items-center justify-center">
              <span className="mb-6 font-circular-book text-base text-white">
                {t("SCANNER_PAGE.PRODUCT_NOT_FOUND_DESCRIPTION")}
              </span>
            </div>

            <div className="mb-5">
              <Button
                type="submit"
                text={t("SCANNER_PAGE.PRODUCT_NOT_FOUND_BUTTON_LABEL")}
                styleType="secondary"
              />
            </div>
          </form>
        </FormProvider>
      </Dialog.Panel>
    </BottomModal>
  );
};
