import { TFirebaseError } from "../../../types";

type TErrorMessages = {
  [key: string]: string;
};
const errorMessages: TErrorMessages = {
  // TODO make it i18n
  "auth/email-already-in-use":
    "Ungültige E-Mail-Adresse. Bitte versuche es erneut.",
  "auth/invalid-email":
    "Email oder Passwort ist falsch. Bitte versuche es erneut.",
  "auth/user-not-found": "Nutzer nicht gefunden",
  "auth/wrong-password":
    "Email oder Passwort ist falsch. Bitte versuche es erneut.",
  default: "Da ist etwas schief gelaufen. Bitte versuche es später erneut.",
};

export function convertFirebaseErrorMessage(error: TFirebaseError): string {
  if (!errorMessages[error.code]) {
    return errorMessages.default;
  }

  return errorMessages[error.code];
}
