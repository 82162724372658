import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useShowNavbarOnScroll } from "../../hooks/useShowNavbarOnScroll";
import { classNames } from "../../../../shared";
import { Transition } from "@headlessui/react";
import { ScanButton } from "./components/ScanButton";
import { ERoutePaths } from "../../../../types";
import HouseSvg from "../../../../assets/house.svg";
import { useIsStandalone } from "../../hooks/useIsStandalone";

export const BottomNavBar = () => {
  const navigate = useNavigate();

  const isStandalone = useIsStandalone();

  const showNavbar = useShowNavbarOnScroll();

  const requestScan = () => {
    navigate(ERoutePaths.PICK_SCANNER);
  };
  return (
    <>
      <Transition
        show={showNavbar}
        enter="ease-in-out duration-200 transform"
        enterFrom="opacity-50 translate-y-40"
        enterTo="opacity-100 translate-y-0"
        leave="ease-in-out duration-300 transform"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-40"
        className={classNames(
          isStandalone ? "pt-4 pb-6" : "h-18 px-16",
          " fixed bottom-0 left-1/2  z-20 flex w-full max-w-5xl -translate-x-1/2   items-center justify-around bg-white bg-opacity-90  backdrop-blur-sm "
        )}
      >
        <Link
          to="/de/suche"
          className="flex h-16 w-16 transform items-center justify-center rounded-full transition-transform active:scale-95 active:bg-gray-100"
        >
          <MagnifyingGlassIcon className="h-8 w-8" />
        </Link>

        <div className="flex h-16 w-16 transform items-center justify-center transition-transform active:scale-95">
          <ScanButton onRequestScan={requestScan} />
        </div>

        <Link
          to="/"
          className="flex h-16 w-16 transform items-center justify-center rounded-full transition-transform active:scale-95 active:bg-gray-100"
        >
          <img
            data-cy="nav-bar-home-button"
            className="h-7 w-7 object-contain"
            src={HouseSvg}
            alt="logo"
          />
        </Link>
      </Transition>
    </>
  );
};
