import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

type TErrorDialogProps = {
  error: Error | string| null;
  onClose: () => void;
  title: string;
  description: string;
  buttonLabel: string;
};

export default function ErrorDialog({
  error,
  onClose,
  title,
  description,
  buttonLabel,
}: TErrorDialogProps) {
  return (
    <Transition.Root show={!!error} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="bg-gray-500 fixed inset-0 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="bg:wine-red-transparent mx-auto flex h-12 w-12 items-center justify-center rounded-full">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-wine-red"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg text-gray-900 font-medium leading-6">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="font-pangea-regular text-sm text-black">
                        {description}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="button"
                    className="border-transparent inline-flex  w-full justify-center rounded-3xl border bg-wine-red px-4 py-2 text-base font-medium text-white shadow-sm hover:opacity-80 focus:outline-none sm:text-sm"
                    onClick={onClose}>
                    {buttonLabel}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
