import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { useElementOnScreen } from "../../../../hooks/useElementOnScreen";
import { classNames } from "../../../../shared";
import { currentlyVisibleMatchAtom } from "../../../../state/atoms/currentlyVisibleMatchAtom";
import { TProduct } from "../../../../types";
import { truncateString } from "../../../utils/truncateString";
import { PriceSectionMatchCard } from "./PriceSectionMatchCard";
import { trackProductClick } from "../../../../Tracking/general/generalTrackingEvents";

const CDN_URL =
  import.meta.env.VITE_CDN_URL || "https://www.claire.digital/cdn/claire/";

type TColorMatchCardProps = {
  match: TProduct;
  index: number;
};

export const ColorMatchCard = ({ match, index }: TColorMatchCardProps) => {
  const setCurrentlyVisibleMatch = useSetRecoilState(currentlyVisibleMatchAtom);
  const location = useLocation();

  const brandname = truncateString(match.brand, 15);
  const productName = truncateString(match.name, 20);
  const rangeName = truncateString(match.range1, 20);

  const [containerRef, isVisible] = useElementOnScreen({
    threshold: 0.6,
    reappear: true,
  });

  useEffect(() => {
    if (location.state?.previouslySelectedGtin === match.gtin) {
      containerRef.current?.scrollIntoView({
        behavior: "instant" as ScrollBehavior,
      });
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      setCurrentlyVisibleMatch(match.colorhex);
    }
  }, [isVisible]);

  return (
    <div
      data-cy="match-card"
      ref={containerRef}
      className={classNames(
        "relative ml-3 flex h-96 snap-center  flex-col  justify-between rounded-lg bg-white ",
        index === 0 ? "ml-6" : ""
      )}
    >
      <Link
        to={`/de/details/${match.gtin}`}
        state={{ from: location.pathname }}
        onClick={() => trackProductClick(match, index, "match_page")}
        className="relative flex max-h-60 w-72  items-center justify-start rounded-t-lg   bg-gray-100 p-4 pt-8"
      >
        <div className="flex w-full justify-center ">
          <img
            className="h-40 w-20  object-contain"
            src={`${CDN_URL}/${match?.imagefront}`}
            alt=""
          />
        </div>
      </Link>

      <Link
        to={`/de/details/${match.gtin}`}
        state={{ from: location.pathname }}
        onClick={() => trackProductClick(match, index, "match_page")}
        className="px-5 pb-4"
      >
        <div className="flex w-full transform flex-col pt-6 pb-3">
          <span className="font-circular-medium text-xl">{brandname}</span>
          <span className="font-circular-book text-base text-gray-300">
            {rangeName}
          </span>
          <span className="mb-3 font-circular-book text-base text-gray-300">
            {productName}
          </span>
          <PriceSectionMatchCard product={match} />
        </div>
      </Link>
    </div>
  );
};
