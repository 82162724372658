import { captureException } from "@sentry/react";
import { TSearchProductsRequestResponse, TSearchTypeFilter } from "../types";
import { getRequest } from "./getRequest";

export const searchProductsRequest = async (
  searchStringFilter: string,
  categoryFilter: string,
  pageSize?: number,
  pageNumber?: number,
  filterTypes?: TSearchTypeFilter[],
  sortBy?: string,
  sortOrder?: "asc" | "desc"
): Promise<TSearchProductsRequestResponse> => {
  try {
    const searchUrl = createSearchUrl(
      searchStringFilter,
      categoryFilter,
      pageSize,
      pageNumber,
      filterTypes,
      sortBy,
      sortOrder
    );

    const searchProducts = await getRequest(searchUrl);

    return searchProducts;
  } catch (error) {
    console.log(error); // TODO show this to the user
    captureException(error);
    return { totalMatches: 0, products: [] };
  }
};

function createSearchUrl(
  searchStringFilter: string,
  categoryFilter: string,
  pageSize?: number,
  pageNumber?: number,
  filterTypes?: TSearchTypeFilter[],
  sortBy?: string,
  sortOrder?: "asc" | "desc"
): string {
  const sortByQuery =
    sortBy && sortOrder ? `&sortBy=${sortBy}&sortOrder=${sortOrder}` : "";

  const searchStringQuery = searchStringFilter
    ? `&searchString=${searchStringFilter}`
    : "";

  const pageSizeQuery = pageSize ? `&pageSize=${pageSize}` : "";

  const pageNumberQuery = pageNumber ? `&page=${pageNumber}` : "";

  const typeFilters = filterTypes
    ?.filter(
      (filterType) =>
        filterType.selected &&
        filterType.selectedValue !== null &&
        !filterType.filterType
    )
    .map((filterType) => filterType.selectedValue);

  // TODO: this was just a quick workaround, I will refactor the filters to be objects later on
  const bestBrandMatch = filterTypes?.find(
    (filter) => filter.filterType === "bestBrandMatch"
  );
  // filterTypes = filterTypes?.filter(
  //   (filter) => filter !== "catrice" && filter !== "essence" && filter !== "opi"
  // );

  const bestBrandMatchQuery = bestBrandMatch
    ? `&bestBrandMatch=${bestBrandMatch.selectedValue}`
    : "";

  const filterTypesQuery =
    typeFilters && typeFilters.length > 0
      ? `&filterTypes=${typeFilters
          .filter((filter) => filter !== null)
          .join(",")}`
      : "";

  const searchUrl = `${
    import.meta.env.VITE_BACKEND_URL
  }/searchProducts/?category=${categoryFilter}${searchStringQuery}${pageSizeQuery}${pageNumberQuery}${filterTypesQuery}${sortByQuery}${bestBrandMatchQuery}`;

  return searchUrl;
}
