import { atom, selector } from "recoil";
import { getRecentlyAddedDupesFilters } from "../../thridparty/firebase/db/getRecentlyAddedDupesFilters";

export const recentlyAddedDupesAtom = atom({
  key: "RecentlyAddedDupesAtom",
  default: selector({
    key: "RecentlyAddedDupesAtom/Default",
    get: async () => getRecentlyAddedDupesFilters(),
  }),
});
