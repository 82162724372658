import { t } from "i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

export const getNewsLetterLabel = (checked: boolean) => {
  return checked ? (
    <Trans
      i18nKey="REGISTER_PAGE.NEWSLETTER_LABEL_LONG"
      components={{
        link1: (
          <Link
            className="text-white underline"
            to={"/de/datenschutz"}
            title="Datenschutzerklärung"
          >
            Datenschutzerklärung
          </Link>
        ),
      }}
    />
  ) : (
    <Trans
      i18nKey="REGISTER_PAGE.NEWSLETTER_LABEL_SHORT"
      components={{
        link1: (
          <Link
            className="text-white underline"
            to={"/de/datenschutz"}
            title="Datenschutzerklärung"
          >
            Datenschutzerklärung
          </Link>
        ),
      }}
    />
  );
};
