import { useState, useEffect } from "react";
export const useShowNavbarOnScroll = () => {
  const threshold = 250;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showNavbar, setShowNavbar] = useState(true);

  useEffect(() => {
    const updatePosition = () => {
      const currentPosition = window.scrollY;

      const height =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;

      setScrollPosition(currentPosition);

      if (height - 290 < currentPosition) {
        setShowNavbar(false);
        return;
      }

      if (currentPosition < 50) {
        setShowNavbar(true);
      }

      if (currentPosition > scrollPosition && currentPosition > threshold) {
        setShowNavbar(false);
      } else if (
        currentPosition < scrollPosition &&
        currentPosition > threshold
      ) {
        setShowNavbar(true);
      }
    };

    window.addEventListener("scroll", updatePosition);
    updatePosition();

    return () => window.removeEventListener("scroll", updatePosition);
  }, [threshold, scrollPosition]);

  return showNavbar;
};
