import { useEffect, useRef, useState } from "react";
import { classNames } from "../../../shared";

export const MatchCircle = ({
  children,
  percentage,
  extraClassNames = "h-24 w-24",
  color = "black",
}: {
  extraClassNames?: string;
  children: React.ReactNode;
  percentage: number;
  color?: string;
}) => {
  const refCircle = useRef<SVGCircleElement>(null);
  const circumference = 289.02652413026095;

  useEffect(() => {
    function setStrokeDashOffset(percentage: number) {
      if (!refCircle.current) return;

      refCircle.current.style.strokeDasharray = String(
        Math.round(Math.PI * (2 * 47))
      );

      refCircle.current.style.strokeDashoffset = String(
        Math.round(Math.PI * (2 * 47)) -
          ((0.98 * percentage) / 100) * circumference
      );
    }

    setTimeout(() => {
      setStrokeDashOffset(percentage);
    }, 400);

    setStrokeDashOffset(0);
  }, [percentage]);

  return (
    <div
      className={classNames(
        "relative inline-flex h-24 w-24 items-center justify-center overflow-visible ",
        extraClassNames
      )}
    >
      <svg
        className={classNames(extraClassNames)}
        stroke="currentColor"
        viewBox="0 0 100 100"
        style={{ transform: "rotate(270deg)" }}
        fill="none"
      >
        <defs>
          <linearGradient id="Gradient1" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#DE6873" />
            <stop offset="100%" stopColor="#B0494A" />
          </linearGradient>
        </defs>
        <circle
          className="circle-bg rounded stroke-gray-300"
          cx="50"
          cy="50"
          r="47"
          strokeWidth={6}
          strokeOpacity={0.5}
        />
        <circle
          className="circle-bg origin-center rounded transition-all duration-500"
          stroke={`${color}`}
          cx="50"
          cy="50"
          r="47"
          strokeLinecap="round"
          strokeWidth={6}
          ref={refCircle}
        />
      </svg>

      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
        {children}
      </span>
    </div>
  );
};
