export const getBackCamera = async (): Promise<string | null> => {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return (
      devices.find((device) => device.label === "Rückkamera")?.deviceId || null
    );
  } catch (error) {
    return null;
  }
};
