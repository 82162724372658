import React from "react";
import { Link } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import {
  searchStringFilterAtom,
  searchTypeFiltersAtom,
} from "../../../../state/atoms";
import { useTranslation } from "react-i18next";
import { trackContentClicked } from "../../../../Tracking";

// TODO this should move to the db
type FeatureOption = {
  imgSrc: string;
  query: string;
  name: string;
  filters: {
    id: number;
    value: string;
  }[];
};

const featureOptions: FeatureOption[] = [
  {
    imgSrc: "/v2/images/chanel_nagellack.png",
    query: "Chanel",
    name: "FEATURE_FILTER_LABEL_BEST_CHANEL",
    filters: [
      {
        id: 1,
        value: "Nail Polish",
      },
    ],
  },
  {
    imgSrc: "/v2/images/nagellack_catrice.jpg",
    query: "",
    name: "FEATURE_FILTER_LABEL_NAILPOLISH_BEST_CATRICE_MATCHES",
    filters: [
      {
        id: 5,
        value: "catrice",
      },
      {
        id: 1,
        value: "Nail Polish",
      },
    ],
  },
  {
    imgSrc: "/v2/images/fenty.jpg",
    query: "Fenty",
    name: "FEATURE_FILTER_LABEL_BEST_FENTY_MATCHES",
    filters: [
      {
        id: 5,
        value: "catrice",
      },
      {
        id: 2,
        value: "Lipstick",
      },
      {
        id: 3,

        value: "Lipgloss",
      },
      {
        id: 4,
        value: "Lip Pencil",
      },
    ],
  },
  {
    imgSrc: "/v2/images/lippenstift_essence.jpg",
    query: "",
    name: "FEATURE_FILTER_LABEL_FOUR",
    filters: [
      {
        id: 5,
        value: "essence",
      },
      {
        id: 2,
        value: "Lipstick",
      },
    ],
  },
];

const FeatureMatchesSection: React.FC = () => {
  const setSearchTypeFilters = useSetRecoilState(searchTypeFiltersAtom);
  const setSearchStringFilter = useSetRecoilState(searchStringFilterAtom);

  const { t } = useTranslation();

  const setSearchFilters = (feature: FeatureOption, index: number) => {
    trackContentClicked(feature.name, "", index);
    feature.filters.forEach((filter) => {
      setSearchFilter(filter.id, filter.value);
    });
    feature.query && setSearchStringFilter(feature.query);
  };

  const setSearchFilter = (clickedFilterId: number, filterValue: string) => {
    // TODO clean this code
    setSearchTypeFilters((prev) =>
      prev.map((filter) => {
        const selected =
          filter.type === "button"
            ? !filter.selected
            : filter.type === "dropdown" && filterValue;

        return {
          ...filter,
          selected:
            filter.id === clickedFilterId ? Boolean(selected) : filter.selected,
          selectedValue:
            filter.id === clickedFilterId ? filterValue : filter.selectedValue,
        };
      })
    );
  };

  return (
    <div className="w-full ">
      <div>
        <h2 className="font-circular-book text-base uppercase">
          {t("HOME_PAGE.FEATURE_SECTION_TITLE")}
        </h2>
        <p className="font-ivypresto-regular text-2xl">
          {t("HOME_PAGE.FEATURE_SECTION_SUBTITLE")}
        </p>
      </div>
      <div className="scrollbar-hide mt-7 flex w-full overflow-auto">
        {featureOptions.map((feature, index) => (
          <Link
            key={index}
            className="mr-8 w-60 shrink-0"
            to={"/de/suche"}
            onClick={() => setSearchFilters(feature, index)}
          >
            <img
              alt={feature.name}
              src={feature.imgSrc}
              className="w-60 rounded-lg object-contain "
            />
            <div className="mt-2 font-circular-book leading-5">
              {t(`HOME_PAGE.${feature.name}`)}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default FeatureMatchesSection;
